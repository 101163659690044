import { graphql } from "../../codegen/gql";
import { ChecklistitemscountQuery, QueryCheckListItemInput } from "../../codegen/gql/graphql";
import useGeneratedTypeQuery from "../helpers/useGeneratedTypeQuery";

export const GET_CHECKLISTITEMSCOUNT = graphql(`
  query Checklistitemscount($input: QueryCheckListItemInput!) {
    checklistitemscount(input: $input)
  }
`);

const useCheckListItemsCount = (input: QueryCheckListItemInput) =>
  useGeneratedTypeQuery<ChecklistitemscountQuery, QueryCheckListItemInput>(GET_CHECKLISTITEMSCOUNT, input);

export default useCheckListItemsCount;
