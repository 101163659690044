import BrokerContainer from "./BrokerContainer";
import PlusIcon from "@material-ui/icons/Add";
import { useState } from "react";

export default function BrokerGroupContainer({ group }) {
  const [collapsed, setCollapsed] = useState(true);
  return (
    <div key={group.desk} className="flex justify-center w-full xl:w-2/3 items-center">
      <div onClick={() => setCollapsed(!collapsed)} className="cursor-pointer rounded-xl bg-white w-full flex-wrap mb-2 overflow-hidden">
        <div className="w-full bg-slate-100 p-4 py-3 flex justify-between">
          <p className="text-primary-blue text-[20px]">{group.desk}</p>
          <p className="text-primary-blue">
            <PlusIcon />
          </p>
        </div>
        <div className={`flex flex-wrap justify-start ${collapsed ? "" : "p-4"}`}>
          {!collapsed && group.brokers.map((broker) => <BrokerContainer broker={broker} />)}
        </div>
      </div>
    </div>
  );
}
