import { gql, useMutation } from "@apollo/client";

const MARK_CHECKLISTITEMACTION_AS_DONE = gql`
  mutation MarkCheckListItemActionAsDone($input: MarkCheckListItemActionAsDoneInput!) {
    markchecklistitemactionasdone(input: $input) {
      ... on CheckListItemAction {
        id
        status
        updatedAt
        updatedBy {
          fullName
        }
      }
      ... on Error {
        errorCode
        message
      }
    }
  }
`;

const useMarkCheckListItemActionAsDone = () => {
  const [markCheckListItemActionAsDone] = useMutation(MARK_CHECKLISTITEMACTION_AS_DONE, {
    refetchQueries: ["Checklistitemscount", "Checklistitems"],
  });

  return markCheckListItemActionAsDone;
};

export default useMarkCheckListItemActionAsDone;
