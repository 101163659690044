/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string | number; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  DateTime: { input: any; output: any };
};

export type Broker = {
  __typename?: "Broker";
  brokerName: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  isActive?: Maybe<Scalars["Boolean"]["output"]>;
  numberOfDeals?: Maybe<Scalars["Int"]["output"]>;
  products?: Maybe<Array<Maybe<Product>>>;
  userInfo?: Maybe<UserInfo>;
};

export type Category = {
  __typename?: "Category";
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
};

export type CheckListItem = {
  __typename?: "CheckListItem";
  checkListItemAction: Array<CheckListItemAction>;
  checkListItemType?: Maybe<CheckListItemType>;
  client?: Maybe<Client>;
  createdAt: Scalars["DateTime"]["output"];
  createdBy: UserInfo;
  currentCheckListItemAction?: Maybe<CheckListItemAction>;
  deal?: Maybe<Deal>;
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  deletedBy?: Maybe<UserInfo>;
  id: Scalars["ID"]["output"];
  isCompleted: Scalars["Boolean"]["output"];
  isDeleted: Scalars["Boolean"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
  updatedBy: UserInfo;
};

export type CheckListItemAction = {
  __typename?: "CheckListItemAction";
  actionType: CheckListItemActionType;
  assignedTo: UserInfo;
  checkListItem: CheckListItem;
  createdAt: Scalars["DateTime"]["output"];
  createdBy: UserInfo;
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  deletedBy?: Maybe<UserInfo>;
  id: Scalars["ID"]["output"];
  isDeleted: Scalars["Boolean"]["output"];
  nextCheckListItemAction?: Maybe<CheckListItemAction>;
  nextCheckListItemActionAfterNumberOfDays?: Maybe<Scalars["Int"]["output"]>;
  previousCheckListItemAction?: Maybe<CheckListItemAction>;
  status: CheckListItemActionStatus;
  toBeResolvedAt: Scalars["DateTime"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
  updatedBy: UserInfo;
};

export type CheckListItemActionResult = CheckListItemAction | Error;

export enum CheckListItemActionStatus {
  Done = "DONE",
  Inprogress = "INPROGRESS",
  Todo = "TODO",
}

export enum CheckListItemActionType {
  ProcessOnboardingDocument = "PROCESS_ONBOARDING_DOCUMENT",
  SendClientEmail = "SEND_CLIENT_EMAIL",
  UploadOnboardingDocument = "UPLOAD_ONBOARDING_DOCUMENT",
}

export type CheckListItemResult = CheckListItem | Error;

export enum CheckListItemType {
  Clientkyc = "CLIENTKYC",
  Clientonboarding = "CLIENTONBOARDING",
  Dealcondition = "DEALCONDITION",
  Dealconfirmation = "DEALCONFIRMATION",
  Dealnote = "DEALNOTE",
}

export type Client = {
  __typename?: "Client";
  broker?: Maybe<Broker>;
  clientGroup?: Maybe<ClientGroup>;
  companyName: Scalars["String"]["output"];
  companyNameExact?: Maybe<Scalars["String"]["output"]>;
  country?: Maybe<Country>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  dealNotes?: Maybe<Array<Maybe<DealNote>>>;
  dealsBuyer?: Maybe<Array<Maybe<Deal>>>;
  dealsSeller?: Maybe<Array<Maybe<Deal>>>;
  firstDealDate?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["ID"]["output"];
  idExact?: Maybe<Scalars["String"]["output"]>;
  invoiceEntity?: Maybe<Client>;
  isActive?: Maybe<Scalars["Boolean"]["output"]>;
  productWithBrokers?: Maybe<Array<Maybe<ProductWithBrokers>>>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type ClientProductWithBrokersArgs = {
  productId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type ClientGroup = {
  __typename?: "ClientGroup";
  clientGroupName: Scalars["String"]["output"];
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["ID"]["output"];
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type ClientInput = {
  brokerId: Scalars["Int"]["input"];
  clientGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  companyName: Scalars["String"]["input"];
  companyNameExact?: InputMaybe<Scalars["String"]["input"]>;
  countryId: Scalars["Int"]["input"];
  firstDealDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  idExact?: InputMaybe<Scalars["String"]["input"]>;
};

export type ClientPair = {
  __typename?: "ClientPair";
  buyer?: Maybe<Client>;
  dealsTogetherCount?: Maybe<Scalars["Int"]["output"]>;
  seller?: Maybe<Client>;
};

export type ClientResult = Client | Error;

export type ClientStatistics = {
  __typename?: "ClientStatistics";
  client: Client;
  commissionInEur: Scalars["Float"]["output"];
};

export type ClientUpdateInput = {
  brokerId?: InputMaybe<Scalars["Int"]["input"]>;
  clientGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  companyName?: InputMaybe<Scalars["String"]["input"]>;
  companyNameExact?: InputMaybe<Scalars["String"]["input"]>;
  countryId?: InputMaybe<Scalars["Int"]["input"]>;
  firstDealDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id: Scalars["Int"]["input"];
  idExact?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Count = {
  __typename?: "Count";
  count?: Maybe<Scalars["Int"]["output"]>;
};

export type Country = {
  __typename?: "Country";
  Name?: Maybe<Scalars["String"]["output"]>;
  client?: Maybe<Array<Maybe<Client>>>;
  code?: Maybe<Scalars["String"]["output"]>;
  dealsExport?: Maybe<Array<Maybe<Deal>>>;
  dealsImport?: Maybe<Array<Maybe<Deal>>>;
  id: Scalars["ID"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
};

export type CreateClientOnboardingInput = {
  adminUserId: Scalars["Int"]["input"];
  assignedToUserId: Scalars["Int"]["input"];
  clientId: Scalars["Int"]["input"];
};

export type CreateInvoicesOnExpectedRevenueInput = {
  amount: Scalars["Int"]["input"];
  clientId: Scalars["ID"]["input"];
  invoiceId: Scalars["ID"]["input"];
  olyxRef: Scalars["Int"]["input"];
};

export type CreateOrUpdateInvoicesOnExpectedRevenueInput = {
  expectedRevenueId: Scalars["ID"]["input"];
  invoiceAmount: Scalars["Int"]["input"];
  invoiceId: Scalars["ID"]["input"];
};

export type Currency = {
  __typename?: "Currency";
  description: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
};

export type CurrencyExchangeRate = {
  __typename?: "CurrencyExchangeRate";
  exchangeRate?: Maybe<Scalars["Float"]["output"]>;
  fromCurrency?: Maybe<Currency>;
  id?: Maybe<Scalars["ID"]["output"]>;
  timestamp?: Maybe<Scalars["DateTime"]["output"]>;
  toCurrency?: Maybe<Currency>;
};

export type DashBoardPriceHistory = {
  __typename?: "DashBoardPriceHistory";
  agent?: Maybe<Scalars["String"]["output"]>;
  extraInfo?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  info?: Maybe<Scalars["String"]["output"]>;
  price?: Maybe<Scalars["Float"]["output"]>;
  product?: Maybe<Scalars["String"]["output"]>;
  productId?: Maybe<Scalars["String"]["output"]>;
  timestamp?: Maybe<Scalars["DateTime"]["output"]>;
  userUid?: Maybe<Scalars["String"]["output"]>;
};

export type Deal = {
  __typename?: "Deal";
  brokerBuyer?: Maybe<Broker>;
  brokerSeller?: Maybe<Broker>;
  buyer?: Maybe<Client>;
  commissionBuyer?: Maybe<Scalars["String"]["output"]>;
  commissionSeller?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  currencyCommissionBuyer?: Maybe<Currency>;
  currencyCommissionSeller?: Maybe<Currency>;
  currencyDeal?: Maybe<Currency>;
  dealConditions: Array<DealCondition>;
  dealConfirmationBuy?: Maybe<DealConfirmation>;
  dealConfirmationSell?: Maybe<DealConfirmation>;
  dealDate?: Maybe<Scalars["DateTime"]["output"]>;
  dealNotes?: Maybe<Array<Maybe<DealNote>>>;
  dealStatus: DealStatus;
  deliveries?: Maybe<Array<Maybe<Delivery>>>;
  description?: Maybe<Scalars["String"]["output"]>;
  expectedRevenue?: Maybe<Array<Maybe<ExpectedRevenue>>>;
  expectedRevenueAgainstInvoices?: Maybe<Array<Maybe<ExpectedRevenueAgainstInvoices>>>;
  exportCountry?: Maybe<Country>;
  hasOpenDealCondition?: Maybe<Scalars["Boolean"]["output"]>;
  hasOpenDealConfirmation?: Maybe<Scalars["Boolean"]["output"]>;
  hasOpenDealNote?: Maybe<Scalars["Boolean"]["output"]>;
  id: Scalars["ID"]["output"];
  importCountry?: Maybe<Country>;
  invoices?: Maybe<Array<Maybe<Invoice>>>;
  olyxRef: Scalars["Int"]["output"];
  pricePerUnit?: Maybe<Scalars["String"]["output"]>;
  product?: Maybe<Product>;
  productSpec: ProductSpec;
  seller?: Maybe<Client>;
  termsAgreement?: Maybe<TermsAgreement>;
  termsRegion?: Maybe<TermsRegion>;
  unit?: Maybe<Unit>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
  updatedBy?: Maybe<Broker>;
  volume?: Maybe<Scalars["String"]["output"]>;
};

export type DealCondition = {
  __typename?: "DealCondition";
  createdAt: Scalars["DateTime"]["output"];
  createdBy: UserInfo;
  deal: Deal;
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  isConfirmed: Scalars["Boolean"]["output"];
  isDeleted: Scalars["Boolean"]["output"];
  toBeResolvedAt?: Maybe<Scalars["DateTime"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  updatedBy: UserInfo;
};

export type DealConditionInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  toBeResolvedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type DealConditionResult = DealCondition | Error;

export type DealConfirmation = {
  __typename?: "DealConfirmation";
  createdAt: Scalars["DateTime"]["output"];
  createdBy: UserInfo;
  deal: Deal;
  id: Scalars["ID"]["output"];
  isConfirmed: Scalars["Boolean"]["output"];
  tradeSide: TradeSide;
  updatedAt: Scalars["DateTime"]["output"];
  updatedBy: UserInfo;
};

export type DealConfirmationResult = DealConfirmation | Error;

export type DealCount = {
  __typename?: "DealCount";
  broker?: Maybe<Broker>;
  numberOfDeals?: Maybe<Scalars["Int"]["output"]>;
};

export type DealInput = {
  brokerBuyerId?: InputMaybe<Scalars["ID"]["input"]>;
  brokerSellerId?: InputMaybe<Scalars["ID"]["input"]>;
  buyerId?: InputMaybe<Scalars["ID"]["input"]>;
  commissionBuyer?: InputMaybe<Scalars["String"]["input"]>;
  commissionSeller?: InputMaybe<Scalars["String"]["input"]>;
  currencyCommissionBuyerId?: InputMaybe<Scalars["ID"]["input"]>;
  currencyCommissionSellerId?: InputMaybe<Scalars["ID"]["input"]>;
  currencyDealId?: InputMaybe<Scalars["ID"]["input"]>;
  dateExpectedBuyer?: InputMaybe<Scalars["DateTime"]["input"]>;
  dateExpectedSeller?: InputMaybe<Scalars["DateTime"]["input"]>;
  dealCondition?: InputMaybe<DealConditionInput>;
  dealDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  dealId?: InputMaybe<Scalars["ID"]["input"]>;
  dealStatusId: Scalars["ID"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  exportCountryId?: InputMaybe<Scalars["ID"]["input"]>;
  importCountryId?: InputMaybe<Scalars["ID"]["input"]>;
  newInput?: InputMaybe<Scalars["Boolean"]["input"]>;
  olyxRef?: InputMaybe<Scalars["String"]["input"]>;
  pricePerUnit?: InputMaybe<Scalars["String"]["input"]>;
  productId?: InputMaybe<Scalars["ID"]["input"]>;
  productSpecInput?: InputMaybe<ProductSpecInput>;
  sellerId?: InputMaybe<Scalars["ID"]["input"]>;
  termsAgreementId?: InputMaybe<Scalars["ID"]["input"]>;
  termsRegion?: InputMaybe<Scalars["String"]["input"]>;
  termsRegionId?: InputMaybe<Scalars["ID"]["input"]>;
  unitId?: InputMaybe<Scalars["ID"]["input"]>;
  updatedById?: InputMaybe<Scalars["String"]["input"]>;
  volume?: InputMaybe<Scalars["String"]["input"]>;
};

export enum DealInvoiceStatus {
  Hasabouttocreate = "HASABOUTTOCREATE",
  Hasabouttocreateuntilthisyear = "HASABOUTTOCREATEUNTILTHISYEAR",
  Hasabouttoinvoice = "HASABOUTTOINVOICE",
  Hasabouttosendexact = "HASABOUTTOSENDEXACT",
  Hasdue0 = "HASDUE0",
  Hasdue1 = "HASDUE1",
  Hasdue2 = "HASDUE2",
  Hasdue3 = "HASDUE3",
  Hasdue4 = "HASDUE4",
  Hasexprevmininvdiff = "HASEXPREVMININVDIFF",
  Hasnoinvoicedate = "HASNOINVOICEDATE",
  Hasoutstanding = "HASOUTSTANDING",
  Haspaid = "HASPAID",
  Hasshouldbesentbutisnotsent = "HASSHOULDBESENTBUTISNOTSENT",
  Hasshouldhavebeensentexact = "HASSHOULDHAVEBEENSENTEXACT",
  Hasshouldhavebeensentolyxbase = "HASSHOULDHAVEBEENSENTOLYXBASE",
  Hasstillinvoicedthisyear = "HASSTILLINVOICEDTHISYEAR",
  Hasunknown = "HASUNKNOWN",
  Hasunknownbutcancelled = "HASUNKNOWNBUTCANCELLED",
}

export type DealNote = {
  __typename?: "DealNote";
  client?: Maybe<Client>;
  createdAt: Scalars["DateTime"]["output"];
  createdBy: UserInfo;
  deal: Deal;
  doneAt?: Maybe<Scalars["DateTime"]["output"]>;
  doneBy?: Maybe<UserInfo>;
  id: Scalars["ID"]["output"];
  inProgressAt?: Maybe<Scalars["DateTime"]["output"]>;
  inProgressBy?: Maybe<UserInfo>;
  mentions?: Maybe<Array<DealNoteMention>>;
  note: Scalars["String"]["output"];
  status: DealNoteStatus;
};

export type DealNoteInput = {
  clientId?: InputMaybe<Scalars["ID"]["input"]>;
  dealId: Scalars["ID"]["input"];
  id?: InputMaybe<Scalars["ID"]["input"]>;
  mentions?: InputMaybe<Array<InputMaybe<DealNoteMentionInput>>>;
  note: Scalars["String"]["input"];
};

export type DealNoteMention = {
  __typename?: "DealNoteMention";
  dealNote?: Maybe<DealNote>;
  userInfo?: Maybe<UserInfo>;
};

export type DealNoteMentionInput = {
  mentionedUserId: Scalars["ID"]["input"];
};

export type DealNoteResult = DealNote | Error;

export enum DealNoteStatus {
  Done = "DONE",
  Inprogress = "INPROGRESS",
  Todo = "TODO",
}

export type DealNotesOverview = {
  __typename?: "DealNotesOverview";
  dealNotesDone: Array<DealNote>;
  dealNotesInProgress: Array<DealNote>;
  dealNotesTodo: Array<DealNote>;
};

export type DealResult = Deal | Error;

export type DealStatistics = {
  __typename?: "DealStatistics";
  after?: Maybe<Scalars["DateTime"]["output"]>;
  before?: Maybe<Scalars["DateTime"]["output"]>;
  brokers?: Maybe<Array<Maybe<Broker>>>;
  clientStatistics: Array<ClientStatistics>;
  commissionTotalEur?: Maybe<Scalars["Float"]["output"]>;
  dealCount?: Maybe<Scalars["Int"]["output"]>;
  deals?: Maybe<Array<Maybe<Deal>>>;
  distinctBrokerCount?: Maybe<Scalars["Int"]["output"]>;
  distinctClientCount: Scalars["Int"]["output"];
  distinctProductCount: Scalars["Int"]["output"];
  productStatistics: Array<ProductStatistics>;
  totalVolumePerUnit?: Maybe<Array<Maybe<VolumeInfo>>>;
  tradeRoutesClient?: Maybe<Array<Maybe<ClientPair>>>;
  tradeRoutesClientCount?: Maybe<Scalars["Int"]["output"]>;
};

export type DealStatus = {
  __typename?: "DealStatus";
  description: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  isActive?: Maybe<Scalars["Boolean"]["output"]>;
};

export type Delivery = {
  __typename?: "Delivery";
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdBy?: Maybe<UserInfo>;
  dateDelivery?: Maybe<Scalars["DateTime"]["output"]>;
  deal?: Maybe<Deal>;
  expectedRevenueBuy?: Maybe<ExpectedRevenue>;
  expectedRevenueSell?: Maybe<ExpectedRevenue>;
  id?: Maybe<Scalars["ID"]["output"]>;
  isCompleted?: Maybe<Scalars["Boolean"]["output"]>;
  note?: Maybe<Scalars["String"]["output"]>;
  price?: Maybe<Scalars["Int"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
  updatedBy?: Maybe<UserInfo>;
  volumeActual?: Maybe<Scalars["Int"]["output"]>;
  volumePlanned?: Maybe<Scalars["Int"]["output"]>;
};

export type DeliveryInput = {
  dateDelivery: Scalars["DateTime"]["input"];
  note?: InputMaybe<Scalars["String"]["input"]>;
  price?: InputMaybe<Scalars["Int"]["input"]>;
  volumeActual?: InputMaybe<Scalars["Int"]["input"]>;
  volumePlanned?: InputMaybe<Scalars["Int"]["input"]>;
};

export type DeliveryLongTermInput = {
  dealId: Scalars["Int"]["input"];
  deliveriesToBeCreated: Array<DeliveryToBeCreated>;
};

export type DeliveryResult = Delivery | Error;

export type DeliveryToBeCreated = {
  delivery: DeliveryInput;
  expectedRevenueBuyer?: InputMaybe<ExpectedRevenueInput>;
  expectedRevenueSeller?: InputMaybe<ExpectedRevenueInput>;
};

export type Error = {
  __typename?: "Error";
  errorCode?: Maybe<Scalars["String"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
};

export type ExactItem = {
  __typename?: "ExactItem";
  code?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  idExact?: Maybe<Scalars["ID"]["output"]>;
};

export type ExpectedRevenue = {
  __typename?: "ExpectedRevenue";
  allocatedFromAllInvoices?: Maybe<Scalars["Int"]["output"]>;
  allocatedFromParentInvoice?: Maybe<Scalars["Int"]["output"]>;
  client?: Maybe<Client>;
  currency?: Maybe<Currency>;
  dateExpected?: Maybe<Scalars["DateTime"]["output"]>;
  deal?: Maybe<Deal>;
  deliveries?: Maybe<Array<Maybe<Delivery>>>;
  dueEur?: Maybe<IntResult>;
  expectedRevenueChanges?: Maybe<Array<Maybe<ExpectedRevenueChange>>>;
  id: Scalars["ID"]["output"];
  invoiceStatusSummary?: Maybe<InvoiceStatusSummary>;
  invoiceSumEurAllocatedToExpectedRevenue?: Maybe<Scalars["Int"]["output"]>;
  invoices?: Maybe<Array<Maybe<InvoiceExact>>>;
  note?: Maybe<Scalars["String"]["output"]>;
  paidEur?: Maybe<Scalars["Int"]["output"]>;
  totalAmount?: Maybe<Scalars["Int"]["output"]>;
  totalAmountPlusChanges?: Maybe<Scalars["Int"]["output"]>;
  tradeSide?: Maybe<TradeSide>;
  volume?: Maybe<Scalars["Int"]["output"]>;
};

export type ExpectedRevenueDueEurArgs = {
  maxDays: Scalars["Int"]["input"];
  minDays: Scalars["Int"]["input"];
};

export type ExpectedRevenueAgainstInvoices = {
  __typename?: "ExpectedRevenueAgainstInvoices";
  allInvoicesAreClosed?: Maybe<Scalars["Boolean"]["output"]>;
  clientOnDeal?: Maybe<Client>;
  clientOnExpectedRevenue?: Maybe<Array<Maybe<Client>>>;
  clientOnInvoice?: Maybe<Array<Maybe<Client>>>;
  currenciesMatch?: Maybe<Scalars["Boolean"]["output"]>;
  deal?: Maybe<Deal>;
  dealId: Scalars["ID"]["output"];
  differenceInAmount?: Maybe<Scalars["Float"]["output"]>;
  expectedAmount?: Maybe<Scalars["Float"]["output"]>;
  expectedRevenue?: Maybe<Array<Maybe<ExpectedRevenue>>>;
  invoiceAmount?: Maybe<Scalars["Float"]["output"]>;
  invoiceStatusSummary?: Maybe<InvoiceStatusSummary>;
  invoicedPerStatus?: Maybe<Array<Maybe<InvoicedRevenue>>>;
  invoices?: Maybe<Array<Maybe<InvoiceExact>>>;
  judgement?: Maybe<Scalars["Boolean"]["output"]>;
  numberOfExpectedRevenue?: Maybe<Scalars["Int"]["output"]>;
  numberOfInvoices?: Maybe<Scalars["Int"]["output"]>;
  tradeSide?: Maybe<TradeSide>;
};

export type ExpectedRevenueChange = {
  __typename?: "ExpectedRevenueChange";
  amount?: Maybe<Scalars["Int"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdBy?: Maybe<UserInfo>;
  description?: Maybe<Scalars["String"]["output"]>;
  expectedRevenue?: Maybe<ExpectedRevenue>;
  id: Scalars["ID"]["output"];
  reason?: Maybe<Scalars["String"]["output"]>;
};

export type ExpectedRevenueChangeInput = {
  amount: Scalars["String"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  expectedRevenueId: Scalars["ID"]["input"];
  expectedRevenueReasonId: Scalars["ID"]["input"];
};

export type ExpectedRevenueChangeResult = Error | ExpectedRevenueChange;

export type ExpectedRevenueError = {
  __typename?: "ExpectedRevenueError";
  errorCode?: Maybe<Scalars["String"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
};

export type ExpectedRevenueFromDealInput = {
  dateExpectedBuyer?: InputMaybe<Scalars["DateTime"]["input"]>;
  dateExpectedSeller?: InputMaybe<Scalars["DateTime"]["input"]>;
  dealId: Scalars["ID"]["input"];
};

export type ExpectedRevenueInput = {
  clientId?: InputMaybe<Scalars["Int"]["input"]>;
  currencyId?: InputMaybe<Scalars["Int"]["input"]>;
  dateExpected?: InputMaybe<Scalars["DateTime"]["input"]>;
  dealId?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  note?: InputMaybe<Scalars["String"]["input"]>;
  totalAmount?: InputMaybe<Scalars["Int"]["input"]>;
  tradeSide?: InputMaybe<TradeSide>;
  volume?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ExpectedRevenueReason = {
  __typename?: "ExpectedRevenueReason";
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
};

export type ExpectedRevenueResult = ExpectedRevenue | ExpectedRevenueError;

export enum ExpectedRevenueStatus {
  Abouttocreate = "ABOUTTOCREATE",
  Abouttosend = "ABOUTTOSEND",
  Shouldbesentbutisnotsent = "SHOULDBESENTBUTISNOTSENT",
}

export type ExpectedRevenueSum = {
  __typename?: "ExpectedRevenueSum";
  amount?: Maybe<Scalars["Float"]["output"]>;
  currency?: Maybe<Currency>;
  invoiced?: Maybe<Array<Maybe<InvoicedRevenue>>>;
  invoicedTotalAmount?: Maybe<Scalars["Float"]["output"]>;
};

export type Feed = {
  __typename?: "Feed";
  brokers: Array<Broker>;
  count?: Maybe<Scalars["Int"]["output"]>;
};

export type FgasRegistry = {
  __typename?: "FgasRegistry";
  companyName?: Maybe<Scalars["String"]["output"]>;
  country?: Maybe<Scalars["String"]["output"]>;
  date?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["ID"]["output"];
  region?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
};

export type FgasRegistryInput = {
  companyName?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  date?: InputMaybe<Scalars["String"]["input"]>;
  region?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
};

export type FloatBox = {
  __typename?: "FloatBox";
  value?: Maybe<Scalars["Float"]["output"]>;
};

export type FloatResult = Error | FloatBox;

export type ForwardPrice = {
  __typename?: "ForwardPrice";
  currency?: Maybe<Currency>;
  id?: Maybe<Scalars["ID"]["output"]>;
  marketProduct?: Maybe<MarketProduct>;
  price?: Maybe<Scalars["Float"]["output"]>;
  term?: Maybe<Scalars["String"]["output"]>;
  timestamp?: Maybe<Scalars["DateTime"]["output"]>;
  unit?: Maybe<Unit>;
};

export type ForwardPriceInput = {
  currencyId: Scalars["ID"]["input"];
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  marketProduct?: InputMaybe<Scalars["String"]["input"]>;
  marketProductId?: InputMaybe<Scalars["ID"]["input"]>;
  price: Scalars["Float"]["input"];
  term: Scalars["String"]["input"];
  unitId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type IntBox = {
  __typename?: "IntBox";
  value?: Maybe<Scalars["Int"]["output"]>;
};

export type IntResult = Error | IntBox;

export type Invoice = {
  __typename?: "Invoice";
  client: Client;
  currencyInvoice: Currency;
  deals?: Maybe<Array<Deal>>;
  id: Scalars["ID"]["output"];
  invoicePaid: Scalars["Boolean"]["output"];
  invoiceSent: Scalars["Boolean"]["output"];
  invoiceSentDate?: Maybe<Scalars["DateTime"]["output"]>;
  scheduleSendOutInvoice?: Maybe<Scalars["DateTime"]["output"]>;
  totalAmount: Scalars["Float"]["output"];
};

export type InvoiceAllocation = {
  __typename?: "InvoiceAllocation";
  amountAllocatedToExpectedRevenue?: Maybe<Scalars["Int"]["output"]>;
  expectedRevenue?: Maybe<Array<Maybe<ExpectedRevenue>>>;
  invoice?: Maybe<InvoiceExact>;
  invoiceTotalAmount?: Maybe<Scalars["Int"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
};

export type InvoiceAllocationResult = Error | InvoiceAllocation;

export type InvoiceExact = {
  __typename?: "InvoiceExact";
  allocatedToAllExpectedRevenue?: Maybe<Scalars["Int"]["output"]>;
  allocatedToParentExpectedRevenue?: Maybe<Scalars["Int"]["output"]>;
  allocationStatus?: Maybe<Scalars["String"]["output"]>;
  client?: Maybe<Client>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  currency?: Maybe<Currency>;
  dateDue?: Maybe<Scalars["DateTime"]["output"]>;
  dateEntry?: Maybe<Scalars["DateTime"]["output"]>;
  dateInvoice?: Maybe<Scalars["DateTime"]["output"]>;
  dateOrder?: Maybe<Scalars["DateTime"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  expectedRevenue?: Maybe<Array<Maybe<ExpectedRevenue>>>;
  id: Scalars["ID"]["output"];
  idExact?: Maybe<Scalars["ID"]["output"]>;
  invoiceNumber?: Maybe<Scalars["String"]["output"]>;
  invoiceStatus?: Maybe<InvoiceStatus>;
  invoiceStatusSummary?: Maybe<InvoiceStatusSummary>;
  isDeleted?: Maybe<Scalars["Boolean"]["output"]>;
  orderNumber?: Maybe<Scalars["String"]["output"]>;
  totalAmount?: Maybe<Scalars["Int"]["output"]>;
  totalAmountEur?: Maybe<Scalars["Int"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
  vatAmountDc?: Maybe<Scalars["Int"]["output"]>;
  vatAmountFc?: Maybe<Scalars["Int"]["output"]>;
};

export type InvoiceExactInput = {
  clientName?: InputMaybe<Scalars["String"]["input"]>;
  currency?: InputMaybe<Scalars["String"]["input"]>;
  dateDue?: InputMaybe<Scalars["String"]["input"]>;
  dateEntry?: InputMaybe<Scalars["String"]["input"]>;
  dateInvoice?: InputMaybe<Scalars["String"]["input"]>;
  dateOrder?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  expectedRevenueId?: InputMaybe<Scalars["ID"]["input"]>;
  idExact?: InputMaybe<Scalars["ID"]["input"]>;
  invoiceNumber?: InputMaybe<Scalars["String"]["input"]>;
  invoiceStatusId?: InputMaybe<Scalars["ID"]["input"]>;
  orderNumber?: InputMaybe<Scalars["String"]["input"]>;
  totalAmount?: InputMaybe<Scalars["Float"]["input"]>;
  totalAmountEur?: InputMaybe<Scalars["Float"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  vatAmountDc?: InputMaybe<Scalars["Float"]["input"]>;
  vatAmountFc?: InputMaybe<Scalars["Float"]["input"]>;
};

export type InvoiceExactResult = Error | InvoiceExact;

export type InvoiceStatus = {
  __typename?: "InvoiceStatus";
  id: Scalars["ID"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
};

export enum InvoiceStatusSummary {
  Abouttosend = "ABOUTTOSEND",
  Cancelled = "CANCELLED",
  Currencyerror = "CURRENCYERROR",
  Deleted = "DELETED",
  Due = "DUE",
  Error = "ERROR",
  Future = "FUTURE",
  Nothing = "NOTHING",
  Overdue = "OVERDUE",
  Paid = "PAID",
  Shouldbesentbutisnotsent = "SHOULDBESENTBUTISNOTSENT",
  Unknown = "UNKNOWN",
}

export type InvoiceSum = {
  __typename?: "InvoiceSum";
  amount?: Maybe<Scalars["Float"]["output"]>;
  currency?: Maybe<Currency>;
  expectedRevenue?: Maybe<Array<Maybe<ExpectedRevenue>>>;
  expectedRevenueTotalAmount?: Maybe<Scalars["Float"]["output"]>;
  expectedRevenueTotalAmountBetweenDealDate?: Maybe<Scalars["Float"]["output"]>;
  invoices?: Maybe<Array<Maybe<InvoiceExact>>>;
  perInvoiceStatus?: Maybe<Array<Maybe<InvoicedRevenue>>>;
};

export type InvoicedRevenue = {
  __typename?: "InvoicedRevenue";
  amount?: Maybe<Scalars["Float"]["output"]>;
  invoiceStatus?: Maybe<InvoiceStatus>;
};

export type InvoicesOnExpectedRevenue = {
  __typename?: "InvoicesOnExpectedRevenue";
  assignedAt?: Maybe<Scalars["DateTime"]["output"]>;
  assignedBy?: Maybe<UserInfo>;
  expectedRevenue?: Maybe<ExpectedRevenue>;
  invoice?: Maybe<InvoiceExact>;
  invoiceAmount?: Maybe<Scalars["Int"]["output"]>;
};

export type InvoicesOnExpectedRevenueResult = Error | InvoicesOnExpectedRevenue;

export type MarkCheckListItemActionAsDoneInput = {
  checkListItemActionId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MarkDealConditionAsConfirmedInput = {
  dealConditionId: Scalars["Int"]["input"];
};

export type MarkDealConfirmationAsConfirmedInput = {
  dealConfirmationId: Scalars["Int"]["input"];
};

export type MarketProduct = {
  __typename?: "MarketProduct";
  category?: Maybe<Category>;
  code?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
};

export type MatchInvoicesOnExpectedRevenueInput = {
  dealId: Scalars["ID"]["input"];
};

export type Mutation = {
  __typename?: "Mutation";
  broker: Broker;
  brokerslist: Array<Broker>;
  client: ClientResult;
  clientgroup: ClientGroup;
  clientgrouplist: Array<ClientGroup>;
  clientupdate: ClientResult;
  createclientonboarding: CheckListItemResult;
  createexpectedrevenue: ExpectedRevenueResult;
  createinvoicesonexpectedrevenue?: Maybe<Array<Maybe<InvoicesOnExpectedRevenueResult>>>;
  createorupdateinvoicesonexpectedrevenue: InvoicesOnExpectedRevenueResult;
  currencyexchangerate: CurrencyExchangeRate;
  deal: DealResult;
  dealnotes?: Maybe<Array<Maybe<DealNote>>>;
  deals?: Maybe<Array<DealResult>>;
  deliveries?: Maybe<Array<Maybe<DeliveryResult>>>;
  expectedrevenuechange?: Maybe<Array<Maybe<ExpectedRevenueChangeResult>>>;
  expectedrevenuefromdeal: Array<Maybe<ExpectedRevenueResult>>;
  fgasregistries?: Maybe<Count>;
  forwardprice: ForwardPrice;
  forwardpricelist: Array<ForwardPrice>;
  invoiceexact: InvoiceExact;
  invoicesexact: Array<InvoiceExactResult>;
  markchecklistitemactionasdone: CheckListItemActionResult;
  markdealconditionasconfirmed?: Maybe<DealConditionResult>;
  markdealconfirmationasconfirmed?: Maybe<DealConfirmationResult>;
  markinvoicesasdeleted: Array<InvoiceExactResult>;
  matchinvoicesonexpectedrevenue?: Maybe<Array<Maybe<InvoicesOnExpectedRevenueResult>>>;
  matchinvoicetodeal: InvoiceExactResult;
  pickupdealnote?: Maybe<DealNoteResult>;
  productcreate: ProductResult;
  productspec: ProductSpec;
  productspecproperty: ProductSpecProperty;
  productupdate: ProductResult;
  soup: Soup;
  termsregion: TermsRegion;
  updatedeals?: Maybe<Array<DealResult>>;
};

export type MutationBrokerArgs = {
  brokerName: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationBrokerslistArgs = {
  brokerList?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type MutationClientArgs = {
  input: ClientInput;
};

export type MutationClientgroupArgs = {
  clientGroupName: Scalars["String"]["input"];
};

export type MutationClientgrouplistArgs = {
  clientGroupList?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type MutationClientupdateArgs = {
  input: ClientUpdateInput;
};

export type MutationCreateclientonboardingArgs = {
  input: CreateClientOnboardingInput;
};

export type MutationCreateexpectedrevenueArgs = {
  input: ExpectedRevenueInput;
};

export type MutationCreateinvoicesonexpectedrevenueArgs = {
  input?: InputMaybe<Array<InputMaybe<CreateInvoicesOnExpectedRevenueInput>>>;
};

export type MutationCreateorupdateinvoicesonexpectedrevenueArgs = {
  input: CreateOrUpdateInvoicesOnExpectedRevenueInput;
};

export type MutationCurrencyexchangerateArgs = {
  exchangeRate: Scalars["Float"]["input"];
  fromCurrency: Scalars["ID"]["input"];
  timestamp?: InputMaybe<Scalars["DateTime"]["input"]>;
  toCurrency: Scalars["ID"]["input"];
};

export type MutationDealArgs = {
  input?: InputMaybe<DealInput>;
};

export type MutationDealnotesArgs = {
  input?: InputMaybe<Array<InputMaybe<DealNoteInput>>>;
};

export type MutationDealsArgs = {
  input?: InputMaybe<Array<InputMaybe<DealInput>>>;
};

export type MutationDeliveriesArgs = {
  input: DeliveryLongTermInput;
};

export type MutationExpectedrevenuechangeArgs = {
  input?: InputMaybe<Array<ExpectedRevenueChangeInput>>;
};

export type MutationExpectedrevenuefromdealArgs = {
  input?: InputMaybe<Array<InputMaybe<ExpectedRevenueFromDealInput>>>;
};

export type MutationFgasregistriesArgs = {
  input: Array<FgasRegistryInput>;
};

export type MutationForwardpriceArgs = {
  currencyId: Scalars["ID"]["input"];
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  marketProductId: Scalars["ID"]["input"];
  price: Scalars["Float"]["input"];
  term: Scalars["String"]["input"];
  unitId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationForwardpricelistArgs = {
  input?: InputMaybe<Array<InputMaybe<ForwardPriceInput>>>;
};

export type MutationInvoiceexactArgs = {
  input?: InputMaybe<InvoiceExactInput>;
};

export type MutationInvoicesexactArgs = {
  input: Array<InvoiceExactInput>;
};

export type MutationMarkchecklistitemactionasdoneArgs = {
  input: MarkCheckListItemActionAsDoneInput;
};

export type MutationMarkdealconditionasconfirmedArgs = {
  input?: InputMaybe<MarkDealConditionAsConfirmedInput>;
};

export type MutationMarkdealconfirmationasconfirmedArgs = {
  input?: InputMaybe<MarkDealConfirmationAsConfirmedInput>;
};

export type MutationMarkinvoicesasdeletedArgs = {
  ids: Array<Scalars["ID"]["input"]>;
};

export type MutationMatchinvoicesonexpectedrevenueArgs = {
  input?: InputMaybe<Array<InputMaybe<MatchInvoicesOnExpectedRevenueInput>>>;
};

export type MutationMatchinvoicetodealArgs = {
  invoiceId: Scalars["Int"]["input"];
};

export type MutationPickupdealnoteArgs = {
  id: Scalars["ID"]["input"];
  status: DealNoteStatus;
};

export type MutationProductcreateArgs = {
  input: ProductCreateInput;
};

export type MutationProductspecArgs = {
  description: Scalars["String"]["input"];
  productId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationProductspecpropertyArgs = {
  productSpecId?: InputMaybe<Scalars["ID"]["input"]>;
  productSpecPropertyTypeId?: InputMaybe<Scalars["ID"]["input"]>;
  value: Scalars["String"]["input"];
};

export type MutationProductupdateArgs = {
  input: ProductUpdateInput;
};

export type MutationSoupArgs = {
  name: Scalars["String"]["input"];
  priceMedium?: InputMaybe<Scalars["Float"]["input"]>;
  priceSmall?: InputMaybe<Scalars["Float"]["input"]>;
};

export type MutationTermsregionArgs = {
  description: Scalars["String"]["input"];
};

export type MutationUpdatedealsArgs = {
  input?: InputMaybe<Array<InputMaybe<DealInput>>>;
};

export type NetworkGraphData = {
  __typename?: "NetworkGraphData";
  links?: Maybe<Array<Maybe<TradingLink>>>;
  nodes?: Maybe<Array<Maybe<TradingNode>>>;
};

export enum NodeType {
  Broker = "BROKER",
  Company = "COMPANY",
}

export enum OrderByDirection {
  Asc = "ASC",
  Desc = "DESC",
}

export type Package = {
  __typename?: "Package";
  id: Scalars["ID"]["output"];
  packageName: Scalars["String"]["output"];
};

export type PaymentPerformance = {
  __typename?: "PaymentPerformance";
  dueEur: TotalSumAndClientsResult;
  expectedAfter?: Maybe<Scalars["DateTime"]["output"]>;
  expectedAmountEur: TotalSumAndClientsResult;
  expectedBefore?: Maybe<Scalars["DateTime"]["output"]>;
  expectedRevenue?: Maybe<Array<Maybe<ExpectedRevenue>>>;
  paidEur: TotalSumAndClientsResult;
};

export type PaymentPerformanceDueEurArgs = {
  maxDays: Scalars["Int"]["input"];
  minDays: Scalars["Int"]["input"];
  numberOfClients?: InputMaybe<Scalars["Int"]["input"]>;
};

export type PaymentPerformanceExpectedAmountEurArgs = {
  numberOfClients?: InputMaybe<Scalars["Int"]["input"]>;
};

export type PaymentPerformancePaidEurArgs = {
  dateInvoiceAfter?: InputMaybe<Scalars["DateTime"]["input"]>;
  dateInvoiceBefore?: InputMaybe<Scalars["DateTime"]["input"]>;
  numberOfClients?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Product = {
  __typename?: "Product";
  category?: Maybe<Category>;
  exactItem?: Maybe<ExactItem>;
  id: Scalars["ID"]["output"];
  isActive?: Maybe<Scalars["Boolean"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export type ProductCreateInput = {
  categoryId: Scalars["Int"]["input"];
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  name: Scalars["String"]["input"];
};

export type ProductResult = Error | Product;

export type ProductSpec = {
  __typename?: "ProductSpec";
  description: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  product?: Maybe<Product>;
};

export type ProductSpecInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  productId?: InputMaybe<Scalars["ID"]["input"]>;
  productSpecPropertyInput?: InputMaybe<Array<InputMaybe<ProductSpecPropertyInput>>>;
};

export type ProductSpecProperty = {
  __typename?: "ProductSpecProperty";
  id: Scalars["ID"]["output"];
  productSpec?: Maybe<ProductSpec>;
  productSpecPropertyType?: Maybe<ProductSpecPropertyType>;
  value: Scalars["String"]["output"];
};

export type ProductSpecPropertyInput = {
  productSpecPropertyTypeId: Scalars["ID"]["input"];
  value: Scalars["String"]["input"];
};

export type ProductSpecPropertyType = {
  __typename?: "ProductSpecPropertyType";
  description: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
};

export type ProductStatistics = {
  __typename?: "ProductStatistics";
  commissionInEur: Scalars["Float"]["output"];
  product: Product;
};

export type ProductUpdateInput = {
  categoryId?: InputMaybe<Scalars["Int"]["input"]>;
  id: Scalars["Int"]["input"];
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type ProductWithBrokers = {
  __typename?: "ProductWithBrokers";
  brokers?: Maybe<Array<Maybe<Broker>>>;
  deals?: Maybe<Array<Maybe<Deal>>>;
  product?: Maybe<Product>;
  side?: Maybe<TradeSide>;
};

export type Query = {
  __typename?: "Query";
  brokers: Array<Broker>;
  categories: Array<Category>;
  checkinvoiceallocation?: Maybe<Array<Maybe<InvoiceAllocationResult>>>;
  checkinvoicesperdeal?: Maybe<Array<Maybe<ExpectedRevenueAgainstInvoices>>>;
  checklistitems: Array<CheckListItem>;
  checklistitemscount: Scalars["Int"]["output"];
  clientgroups: Array<ClientGroup>;
  clients: Array<Client>;
  commission: Scalars["Float"]["output"];
  countries: Array<Country>;
  currencies: Array<Currency>;
  currencyexchangerates?: Maybe<Array<Maybe<CurrencyExchangeRate>>>;
  dashboardpricehistories: Array<DashBoardPriceHistory>;
  dealconditions: Array<DealConditionResult>;
  dealconditionscount: Scalars["Int"]["output"];
  dealconfirmations: Array<DealConfirmationResult>;
  dealconfirmationscount: Scalars["Int"]["output"];
  dealcount?: Maybe<Array<Maybe<DealCount>>>;
  dealnotes: Array<DealNote>;
  dealnotesoverview: DealNotesOverview;
  deals: Array<Deal>;
  dealslist: Array<Deal>;
  dealstatistics?: Maybe<DealStatistics>;
  dealstatuses: Array<DealStatus>;
  expectedrevenue?: Maybe<Array<Maybe<ExpectedRevenue>>>;
  expectedrevenuereason?: Maybe<Array<Maybe<ExpectedRevenueReason>>>;
  feed: Feed;
  fgasregistries: Array<FgasRegistry>;
  forwardprices: Array<ForwardPrice>;
  info: Scalars["String"]["output"];
  invoices: Array<InvoiceExact>;
  invoicesexact: Array<InvoiceExact>;
  invoicesum?: Maybe<Array<Maybe<InvoiceSum>>>;
  marketproducts: Array<MarketProduct>;
  missingdealnumbers?: Maybe<Array<Maybe<Scalars["Int"]["output"]>>>;
  networkgraphdata?: Maybe<NetworkGraphData>;
  opendealnotecount?: Maybe<Scalars["Int"]["output"]>;
  packages: Array<Package>;
  paymentperformanceineur?: Maybe<Array<Maybe<PaymentPerformance>>>;
  products: Array<Product>;
  productspecproperties: Array<ProductSpecProperty>;
  productspecpropertytypes: Array<ProductSpecPropertyType>;
  productspecs: Array<ProductSpec>;
  revenueoverview?: Maybe<RevenueOverview>;
  soups: Array<Soup>;
  termsagreements: Array<TermsAgreement>;
  termsregions: Array<TermsRegion>;
  units: Array<Unit>;
  users?: Maybe<Array<Maybe<UserInfo>>>;
};

export type QueryBrokersArgs = {
  after?: InputMaybe<Scalars["DateTime"]["input"]>;
  before?: InputMaybe<Scalars["DateTime"]["input"]>;
  brokerNames?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryCheckinvoiceallocationArgs = {
  invoiceIds?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
};

export type QueryCheckinvoicesperdealArgs = {
  after?: InputMaybe<Scalars["DateTime"]["input"]>;
  before?: InputMaybe<Scalars["DateTime"]["input"]>;
  brokerIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  clientIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  cursor?: InputMaybe<Scalars["Int"]["input"]>;
  dealIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  olyxRefs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryChecklistitemsArgs = {
  input: QueryCheckListItemInput;
};

export type QueryChecklistitemscountArgs = {
  input: QueryCheckListItemInput;
};

export type QueryClientgroupsArgs = {
  clientGroupNames?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryClientsArgs = {
  companyName?: InputMaybe<Scalars["String"]["input"]>;
  companyNameContainsAtLeastOneWord?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export type QueryCommissionArgs = {
  after?: InputMaybe<Scalars["DateTime"]["input"]>;
  before?: InputMaybe<Scalars["DateTime"]["input"]>;
  currency?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryCountriesArgs = {
  names?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryCurrencyexchangeratesArgs = {
  fromCurrency?: InputMaybe<Scalars["String"]["input"]>;
  timestamp?: InputMaybe<Scalars["DateTime"]["input"]>;
  toCurrency?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryDashboardpricehistoriesArgs = {
  after?: InputMaybe<Scalars["DateTime"]["input"]>;
  agent?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["DateTime"]["input"]>;
  productId?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryDealconditionsArgs = {
  input: QueryDealConditionInput;
};

export type QueryDealconditionscountArgs = {
  input: QueryDealConditionInput;
};

export type QueryDealconfirmationsArgs = {
  input: QueryDealConfirmationInput;
};

export type QueryDealconfirmationscountArgs = {
  input: QueryDealConfirmationInput;
};

export type QueryDealcountArgs = {
  after?: InputMaybe<Scalars["DateTime"]["input"]>;
  before?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type QueryDealnotesArgs = {
  input: QueryDealNotesInput;
};

export type QueryDealnotesoverviewArgs = {
  input: QueryDealNotesOverviewInput;
};

export type QueryDealsArgs = {
  dealStatusId?: InputMaybe<Scalars["ID"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  olyxRef?: InputMaybe<Scalars["String"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryDealslistArgs = {
  input: QueryDealsInput;
};

export type QueryDealstatisticsArgs = {
  input?: InputMaybe<QueryDealsInput>;
};

export type QueryExpectedrevenueArgs = {
  input?: InputMaybe<QueryExpectedRevenueInput>;
};

export type QueryFgasregistriesArgs = {
  after?: InputMaybe<Scalars["DateTime"]["input"]>;
  before?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type QueryInvoicesArgs = {
  input: QueryInvoiceInput;
};

export type QueryInvoicesexactArgs = {
  olyxRef?: InputMaybe<Scalars["String"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryInvoicesumArgs = {
  after: Scalars["DateTime"]["input"];
  before: Scalars["DateTime"]["input"];
  dealDateAfter?: InputMaybe<Scalars["DateTime"]["input"]>;
  dealDateBefore?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type QueryMarketproductsArgs = {
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryNetworkgraphdataArgs = {
  input?: InputMaybe<QueryDealsInput>;
};

export type QueryPaymentperformanceineurArgs = {
  input: QueryPaymentPerformanceInput;
};

export type QueryProductsArgs = {
  names?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryProductspecpropertytypesArgs = {
  descriptions?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryRevenueoverviewArgs = {
  after?: InputMaybe<Scalars["DateTime"]["input"]>;
  before?: InputMaybe<Scalars["DateTime"]["input"]>;
  brokerId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryTermsagreementsArgs = {
  descriptions?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryTermsregionsArgs = {
  descriptions?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryUsersArgs = {
  input: UsersInput;
};

export type QueryCheckListItemInput = {
  checkListItemTypes?: InputMaybe<Array<CheckListItemType>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  status?: InputMaybe<CheckListItemActionStatus>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  toBeResolvedAfter?: InputMaybe<Scalars["DateTime"]["input"]>;
  toBeResolvedBefore?: InputMaybe<Scalars["DateTime"]["input"]>;
  userIds: Array<Scalars["Int"]["input"]>;
};

export type QueryDealConditionInput = {
  isConfirmed?: InputMaybe<Scalars["Boolean"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  toBeResolvedAfter?: InputMaybe<Scalars["DateTime"]["input"]>;
  toBeResolvedBefore?: InputMaybe<Scalars["DateTime"]["input"]>;
  userIds: Array<Scalars["Int"]["input"]>;
};

export type QueryDealConfirmationInput = {
  isConfirmed?: InputMaybe<Scalars["Boolean"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  userIds: Array<Scalars["Int"]["input"]>;
};

export type QueryDealNotesInput = {
  orderByDirection?: InputMaybe<OrderByDirection>;
  orderByProperty?: InputMaybe<Scalars["String"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
};

export type QueryDealNotesOverviewInput = {
  hasSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  includeNoMention?: InputMaybe<Scalars["Boolean"]["input"]>;
  isMentioned?: InputMaybe<Scalars["Boolean"]["input"]>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
};

export type QueryDealsInput = {
  after?: InputMaybe<Scalars["DateTime"]["input"]>;
  before?: InputMaybe<Scalars["DateTime"]["input"]>;
  brokerIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  clientIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  cursor?: InputMaybe<Scalars["ID"]["input"]>;
  dealInvoiceStatus?: InputMaybe<DealInvoiceStatus>;
  dealStatusIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  hasOpenDealNote?: InputMaybe<Scalars["Boolean"]["input"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  olyxRefs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  productIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryExpectedRevenueInput = {
  expectedRevenueStatus?: InputMaybe<ExpectedRevenueStatus>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export type QueryInvoiceInput = {
  cursor?: InputMaybe<Scalars["ID"]["input"]>;
  dateEntryAfter?: InputMaybe<Scalars["DateTime"]["input"]>;
  dateEntryBefore?: InputMaybe<Scalars["DateTime"]["input"]>;
  dateInvoiceAfter?: InputMaybe<Scalars["DateTime"]["input"]>;
  dateInvoiceBefore?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  hasIncorrectAllocation?: InputMaybe<Scalars["Boolean"]["input"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  orderNumber?: InputMaybe<Scalars["String"]["input"]>;
  overdue?: InputMaybe<Scalars["Boolean"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryPaymentPerformanceInput = {
  brokerIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  clientIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  dealSearch?: InputMaybe<QueryDealsInput>;
  expectedRevenueIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export type RevenueOverview = {
  __typename?: "RevenueOverview";
  expectedRevenueSum?: Maybe<Array<Maybe<ExpectedRevenueSum>>>;
};

export type Soup = {
  __typename?: "Soup";
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  priceMedium?: Maybe<Scalars["Float"]["output"]>;
  priceSmall?: Maybe<Scalars["Float"]["output"]>;
};

export type Subscription = {
  __typename?: "Subscription";
  commission?: Maybe<Scalars["Float"]["output"]>;
};

export type SumPerClient = {
  __typename?: "SumPerClient";
  client: Client;
  sum: Scalars["Float"]["output"];
};

export type TermsAgreement = {
  __typename?: "TermsAgreement";
  description: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
};

export type TermsRegion = {
  __typename?: "TermsRegion";
  description: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
};

export type TotalSumAndClients = {
  __typename?: "TotalSumAndClients";
  clients: Array<Maybe<SumPerClient>>;
  totalSum: Scalars["Float"]["output"];
};

export type TotalSumAndClientsResult = Error | TotalSumAndClients;

export enum TradeSide {
  Buy = "BUY",
  Sell = "SELL",
}

export type TradingLink = {
  __typename?: "TradingLink";
  source?: Maybe<Scalars["String"]["output"]>;
  target?: Maybe<Scalars["String"]["output"]>;
};

export type TradingNode = {
  __typename?: "TradingNode";
  id?: Maybe<Scalars["String"]["output"]>;
  img?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  olyxRefs?: Maybe<Array<Maybe<Scalars["Int"]["output"]>>>;
  type?: Maybe<NodeType>;
  value?: Maybe<Scalars["Int"]["output"]>;
};

export type Unit = {
  __typename?: "Unit";
  description: Scalars["String"]["output"];
  descriptionShort?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  isActive?: Maybe<Scalars["Boolean"]["output"]>;
};

export type UnitVolume = {
  __typename?: "UnitVolume";
  totalVolume: Scalars["Float"]["output"];
  unit: Unit;
};

export type UserInfo = {
  __typename?: "UserInfo";
  _count?: Maybe<UserInfoCounts>;
  email?: Maybe<Scalars["String"]["output"]>;
  fullName?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  imageUrl?: Maybe<Scalars["String"]["output"]>;
  initials?: Maybe<Scalars["String"]["output"]>;
  taggable?: Maybe<Scalars["Boolean"]["output"]>;
  userUid?: Maybe<Scalars["String"]["output"]>;
};

export type UserInfoCounts = {
  __typename?: "UserInfoCounts";
  DealNoteMention?: Maybe<Scalars["Int"]["output"]>;
};

export type UsersInput = {
  fullName: Scalars["String"]["input"];
  taggable?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type VolumeInfo = {
  __typename?: "VolumeInfo";
  product: Product;
  volumePerUnit: Array<UnitVolume>;
};

export type ClientMutationVariables = Exact<{
  input: ClientInput;
}>;

export type ClientMutation = {
  __typename?: "Mutation";
  client: { __typename: "Client"; id: string; companyName: string } | { __typename: "Error"; errorCode?: string | null; message?: string | null };
};

export type CreateorupdateinvoicesonexpectedrevenueMutationVariables = Exact<{
  input: CreateOrUpdateInvoicesOnExpectedRevenueInput;
}>;

export type CreateorupdateinvoicesonexpectedrevenueMutation = {
  __typename?: "Mutation";
  createorupdateinvoicesonexpectedrevenue:
    | { __typename?: "Error"; errorCode?: string | null; message?: string | null }
    | {
        __typename?: "InvoicesOnExpectedRevenue";
        invoiceAmount?: number | null;
        assignedAt?: any | null;
        invoice?: {
          __typename?: "InvoiceExact";
          id: string;
          invoiceNumber?: string | null;
          description?: string | null;
          totalAmount?: number | null;
          totalAmountEur?: number | null;
          dateInvoice?: any | null;
          dateDue?: any | null;
          dateEntry?: any | null;
          dateOrder?: any | null;
          orderNumber?: string | null;
          allocationStatus?: string | null;
          allocatedToParentExpectedRevenue?: number | null;
          allocatedToAllExpectedRevenue?: number | null;
          idExact?: string | null;
          vatAmountDc?: number | null;
          vatAmountFc?: number | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          isDeleted?: boolean | null;
          invoiceStatusSummary?: InvoiceStatusSummary | null;
          expectedRevenue?: Array<{
            __typename?: "ExpectedRevenue";
            id: string;
            totalAmount?: number | null;
            dateExpected?: any | null;
            allocatedFromParentInvoice?: number | null;
            paidEur?: number | null;
            totalAmountPlusChanges?: number | null;
            invoiceSumEurAllocatedToExpectedRevenue?: number | null;
            invoiceStatusSummary?: InvoiceStatusSummary | null;
          } | null> | null;
        } | null;
        expectedRevenue?: { __typename?: "ExpectedRevenue"; id: string; totalAmount?: number | null } | null;
      };
};

export type ProductMutationVariables = Exact<{
  input: ProductCreateInput;
}>;

export type ProductMutation = {
  __typename?: "Mutation";
  productcreate:
    | { __typename?: "Error"; errorCode?: string | null; message?: string | null }
    | {
        __typename?: "Product";
        id: string;
        name?: string | null;
        isActive?: boolean | null;
        category?: { __typename?: "Category"; id: string; name: string } | null;
      };
};

export type MarkCheckListItemActionAsDoneMutationVariables = Exact<{
  input: MarkCheckListItemActionAsDoneInput;
}>;

export type MarkCheckListItemActionAsDoneMutation = {
  __typename?: "Mutation";
  markchecklistitemactionasdone:
    | {
        __typename?: "CheckListItemAction";
        id: string;
        status: CheckListItemActionStatus;
        updatedAt: any;
        updatedBy: { __typename?: "UserInfo"; fullName?: string | null };
      }
    | { __typename?: "Error"; errorCode?: string | null; message?: string | null };
};

export type MarkDealConditionAsConfirmedMutationVariables = Exact<{
  input?: InputMaybe<MarkDealConditionAsConfirmedInput>;
}>;

export type MarkDealConditionAsConfirmedMutation = {
  __typename?: "Mutation";
  markdealconditionasconfirmed?:
    | { __typename?: "DealCondition"; id: string; isConfirmed: boolean; updatedAt: any; updatedBy: { __typename?: "UserInfo"; fullName?: string | null } }
    | { __typename?: "Error"; errorCode?: string | null; message?: string | null }
    | null;
};

export type MarkDealConfirmationAsConfirmedMutationVariables = Exact<{
  input?: InputMaybe<MarkDealConfirmationAsConfirmedInput>;
}>;

export type MarkDealConfirmationAsConfirmedMutation = {
  __typename?: "Mutation";
  markdealconfirmationasconfirmed?:
    | { __typename?: "DealConfirmation"; id: string; isConfirmed: boolean; updatedAt: any; updatedBy: { __typename?: "UserInfo"; fullName?: string | null } }
    | { __typename?: "Error"; errorCode?: string | null; message?: string | null }
    | null;
};

export type ClientUpdateMutationVariables = Exact<{
  input: ClientUpdateInput;
}>;

export type ClientUpdateMutation = {
  __typename?: "Mutation";
  clientupdate:
    | {
        __typename: "Client";
        id: string;
        companyName: string;
        firstDealDate?: any | null;
        idExact?: string | null;
        companyNameExact?: string | null;
        isActive?: boolean | null;
        updatedAt?: any | null;
        broker?: { __typename?: "Broker"; id: string; brokerName: string } | null;
        country?: { __typename?: "Country"; id: string } | null;
      }
    | { __typename: "Error"; errorCode?: string | null; message?: string | null };
};

export type ExpectedRevenueMutationVariables = Exact<{
  input: ExpectedRevenueInput;
}>;

export type ExpectedRevenueMutation = {
  __typename?: "Mutation";
  createexpectedrevenue:
    | {
        __typename?: "ExpectedRevenue";
        id: string;
        totalAmount?: number | null;
        volume?: number | null;
        dateExpected?: any | null;
        deal?: { __typename?: "Deal"; id: string } | null;
        client?: { __typename?: "Client"; id: string } | null;
        currency?: { __typename?: "Currency"; id: string } | null;
      }
    | { __typename?: "ExpectedRevenueError"; errorCode?: string | null; message?: string | null };
};

export type ProductUpdateMutationVariables = Exact<{
  input: ProductUpdateInput;
}>;

export type ProductUpdateMutation = {
  __typename?: "Mutation";
  productupdate:
    | { __typename?: "Error"; errorCode?: string | null; message?: string | null }
    | {
        __typename?: "Product";
        id: string;
        name?: string | null;
        isActive?: boolean | null;
        category?: { __typename?: "Category"; id: string; name: string } | null;
      };
};

export type ChecklistitemscountQueryVariables = Exact<{
  input: QueryCheckListItemInput;
}>;

export type ChecklistitemscountQuery = { __typename?: "Query"; checklistitemscount: number };

export type ChecklistitemsQueryVariables = Exact<{
  input: QueryCheckListItemInput;
}>;

export type ChecklistitemsQuery = {
  __typename?: "Query";
  checklistitems: Array<{
    __typename?: "CheckListItem";
    id: string;
    createdAt: any;
    updatedAt: any;
    checkListItemType?: CheckListItemType | null;
    isCompleted: boolean;
    client?: { __typename?: "Client"; companyName: string } | null;
    deal?: { __typename?: "Deal"; id: string; olyxRef: number } | null;
    currentCheckListItemAction?: {
      __typename?: "CheckListItemAction";
      id: string;
      actionType: CheckListItemActionType;
      status: CheckListItemActionStatus;
      toBeResolvedAt: any;
      assignedTo: { __typename?: "UserInfo"; fullName?: string | null; id: string };
    } | null;
    updatedBy: { __typename?: "UserInfo"; id: string; imageUrl?: string | null; fullName?: string | null };
  }>;
};

export type ClientFormOptionsQueryVariables = Exact<{ [key: string]: never }>;

export type ClientFormOptionsQuery = {
  __typename?: "Query";
  clients: Array<{ __typename?: "Client"; id: string; companyName: string }>;
  clientgroups: Array<{ __typename?: "ClientGroup"; id: string; clientGroupName: string }>;
  brokers: Array<{ __typename?: "Broker"; id: string; brokerName: string }>;
  countries: Array<{ __typename?: "Country"; id: string; name?: string | null; code?: string | null }>;
};

export type DealconditionsQueryVariables = Exact<{
  input: QueryDealConditionInput;
}>;

export type DealconditionsQuery = {
  __typename?: "Query";
  dealconditions: Array<
    | {
        __typename?: "DealCondition";
        createdAt: any;
        description?: string | null;
        id: string;
        isConfirmed: boolean;
        isDeleted: boolean;
        toBeResolvedAt?: any | null;
        updatedAt: any;
        createdBy: { __typename?: "UserInfo"; id: string; imageUrl?: string | null; fullName?: string | null };
        deal: { __typename?: "Deal"; id: string; olyxRef: number };
        updatedBy: { __typename?: "UserInfo"; id: string; imageUrl?: string | null; fullName?: string | null };
      }
    | { __typename?: "Error"; errorCode?: string | null; message?: string | null }
  >;
};

export type DealconditionscountQueryVariables = Exact<{
  input: QueryDealConditionInput;
}>;

export type DealconditionscountQuery = { __typename?: "Query"; dealconditionscount: number };

export type DealconfirmationsQueryVariables = Exact<{
  input: QueryDealConfirmationInput;
}>;

export type DealconfirmationsQuery = {
  __typename?: "Query";
  dealconfirmations: Array<
    | {
        __typename?: "DealConfirmation";
        id: string;
        createdAt: any;
        isConfirmed: boolean;
        tradeSide: TradeSide;
        updatedAt: any;
        deal: {
          __typename?: "Deal";
          id: string;
          olyxRef: number;
          seller?: { __typename?: "Client"; id: string; companyName: string } | null;
          buyer?: { __typename?: "Client"; id: string; companyName: string } | null;
        };
        updatedBy: { __typename?: "UserInfo"; id: string; imageUrl?: string | null; fullName?: string | null };
      }
    | { __typename?: "Error"; errorCode?: string | null; message?: string | null }
  >;
};

export type DealconfirmationscountQueryVariables = Exact<{
  input: QueryDealConfirmationInput;
}>;

export type DealconfirmationscountQuery = { __typename?: "Query"; dealconfirmationscount: number };

export type DealnotesoverviewQueryVariables = Exact<{
  input: QueryDealNotesOverviewInput;
}>;

export type DealnotesoverviewQuery = {
  __typename?: "Query";
  dealnotesoverview: {
    __typename?: "DealNotesOverview";
    dealNotesTodo: Array<{
      __typename?: "DealNote";
      id: string;
      createdAt: any;
      note: string;
      inProgressAt?: any | null;
      doneAt?: any | null;
      status: DealNoteStatus;
      createdBy: { __typename?: "UserInfo"; id: string; fullName?: string | null; imageUrl?: string | null };
      inProgressBy?: { __typename?: "UserInfo"; id: string; fullName?: string | null; imageUrl?: string | null } | null;
      doneBy?: { __typename?: "UserInfo"; id: string; fullName?: string | null; imageUrl?: string | null } | null;
      deal: { __typename?: "Deal"; id: string; olyxRef: number };
      mentions?: Array<{ __typename?: "DealNoteMention"; userInfo?: { __typename?: "UserInfo"; id: string; userUid?: string | null } | null }> | null;
    }>;
    dealNotesInProgress: Array<{
      __typename?: "DealNote";
      id: string;
      createdAt: any;
      note: string;
      inProgressAt?: any | null;
      doneAt?: any | null;
      status: DealNoteStatus;
      createdBy: { __typename?: "UserInfo"; id: string; fullName?: string | null; imageUrl?: string | null };
      inProgressBy?: { __typename?: "UserInfo"; id: string; fullName?: string | null; imageUrl?: string | null } | null;
      doneBy?: { __typename?: "UserInfo"; id: string; fullName?: string | null; imageUrl?: string | null } | null;
      deal: { __typename?: "Deal"; id: string; olyxRef: number };
      mentions?: Array<{ __typename?: "DealNoteMention"; userInfo?: { __typename?: "UserInfo"; id: string; userUid?: string | null } | null }> | null;
    }>;
    dealNotesDone: Array<{
      __typename?: "DealNote";
      id: string;
      createdAt: any;
      note: string;
      inProgressAt?: any | null;
      doneAt?: any | null;
      status: DealNoteStatus;
      createdBy: { __typename?: "UserInfo"; id: string; fullName?: string | null; imageUrl?: string | null };
      inProgressBy?: { __typename?: "UserInfo"; id: string; fullName?: string | null; imageUrl?: string | null } | null;
      doneBy?: { __typename?: "UserInfo"; id: string; fullName?: string | null; imageUrl?: string | null } | null;
      deal: { __typename?: "Deal"; id: string; olyxRef: number };
      mentions?: Array<{ __typename?: "DealNoteMention"; userInfo?: { __typename?: "UserInfo"; id: string; userUid?: string | null } | null }> | null;
    }>;
  };
};

export type DealstatisticsQueryVariables = Exact<{
  input?: InputMaybe<QueryDealsInput>;
}>;

export type DealstatisticsQuery = {
  __typename?: "Query";
  dealstatistics?: {
    __typename?: "DealStatistics";
    after?: any | null;
    before?: any | null;
    commissionTotalEur?: number | null;
    dealCount?: number | null;
    distinctBrokerCount?: number | null;
    distinctClientCount: number;
    distinctProductCount: number;
    brokers?: Array<{
      __typename?: "Broker";
      id: string;
      brokerName: string;
      userInfo?: { __typename?: "UserInfo"; imageUrl?: string | null } | null;
    } | null> | null;
    clientStatistics: Array<{
      __typename?: "ClientStatistics";
      commissionInEur: number;
      client: {
        __typename?: "Client";
        id: string;
        companyName: string;
        createdAt?: any | null;
        broker?: { __typename?: "Broker"; id: string; brokerName: string } | null;
      };
    }>;
    productStatistics: Array<{
      __typename?: "ProductStatistics";
      commissionInEur: number;
      product: { __typename?: "Product"; id: string; name?: string | null; category?: { __typename?: "Category"; id: string; name: string } | null };
    }>;
    totalVolumePerUnit?: Array<{
      __typename?: "VolumeInfo";
      product: { __typename?: "Product"; name?: string | null; id: string };
      volumePerUnit: Array<{
        __typename?: "UnitVolume";
        totalVolume: number;
        unit: { __typename?: "Unit"; descriptionShort?: string | null; description: string };
      }>;
    } | null> | null;
  } | null;
};

export type InvoicesQueryVariables = Exact<{
  input: QueryInvoiceInput;
}>;

export type InvoicesQuery = {
  __typename?: "Query";
  invoices: Array<{
    __typename?: "InvoiceExact";
    id: string;
    invoiceNumber?: string | null;
    isDeleted?: boolean | null;
    description?: string | null;
    type?: string | null;
    totalAmount?: number | null;
    totalAmountEur?: number | null;
    dateInvoice?: any | null;
    updatedAt?: any | null;
    orderNumber?: string | null;
    allocationStatus?: string | null;
    allocatedToAllExpectedRevenue?: number | null;
    client?: { __typename?: "Client"; id: string; companyName: string } | null;
    currency?: { __typename?: "Currency"; id: string; description: string } | null;
    invoiceStatus?: { __typename?: "InvoiceStatus"; id: string; name?: string | null } | null;
    expectedRevenue?: Array<{
      __typename?: "ExpectedRevenue";
      id: string;
      totalAmount?: number | null;
      dateExpected?: any | null;
      allocatedFromParentInvoice?: number | null;
      allocatedFromAllInvoices?: number | null;
      currency?: { __typename?: "Currency"; id: string; description: string } | null;
      deal?: { __typename?: "Deal"; id: string; olyxRef: number } | null;
      expectedRevenueChanges?: Array<{ __typename?: "ExpectedRevenueChange"; id: string; amount?: number | null; reason?: string | null } | null> | null;
      invoices?: Array<{
        __typename?: "InvoiceExact";
        id: string;
        totalAmount?: number | null;
        orderNumber?: string | null;
        allocatedToParentExpectedRevenue?: number | null;
        invoiceStatusSummary?: InvoiceStatusSummary | null;
        invoiceNumber?: string | null;
        dateOrder?: any | null;
        isDeleted?: boolean | null;
        currency?: { __typename?: "Currency"; id: string; description: string } | null;
      } | null> | null;
    } | null> | null;
  }>;
};

export type NetworkgraphdataQueryVariables = Exact<{
  input?: InputMaybe<QueryDealsInput>;
}>;

export type NetworkgraphdataQuery = {
  __typename?: "Query";
  networkgraphdata?: {
    __typename?: "NetworkGraphData";
    nodes?: Array<{
      __typename?: "TradingNode";
      name?: string | null;
      value?: number | null;
      id?: string | null;
      img?: string | null;
      olyxRefs?: Array<number | null> | null;
    } | null> | null;
    links?: Array<{ __typename?: "TradingLink"; source?: string | null; target?: string | null } | null> | null;
  } | null;
};

export type OpenDealNoteCountQueryVariables = Exact<{ [key: string]: never }>;

export type OpenDealNoteCountQuery = { __typename?: "Query"; opendealnotecount?: number | null };

export type ProductFormOptionsQueryVariables = Exact<{ [key: string]: never }>;

export type ProductFormOptionsQuery = { __typename?: "Query"; categories: Array<{ __typename?: "Category"; id: string; name: string }> };

export const ClientDocument = ({
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Client" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ClientInput" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "client" },
            arguments: [{ kind: "Argument", name: { kind: "Name", value: "input" }, value: { kind: "Variable", name: { kind: "Name", value: "input" } } }],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Client" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "companyName" } },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Error" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "errorCode" } },
                      { kind: "Field", name: { kind: "Name", value: "message" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<ClientMutation, ClientMutationVariables>;
export const CreateorupdateinvoicesonexpectedrevenueDocument = ({
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "createorupdateinvoicesonexpectedrevenue" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "CreateOrUpdateInvoicesOnExpectedRevenueInput" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createorupdateinvoicesonexpectedrevenue" },
            arguments: [{ kind: "Argument", name: { kind: "Name", value: "input" }, value: { kind: "Variable", name: { kind: "Name", value: "input" } } }],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Error" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "errorCode" } },
                      { kind: "Field", name: { kind: "Name", value: "message" } },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "InvoicesOnExpectedRevenue" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "invoiceAmount" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "invoice" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "invoiceNumber" } },
                            { kind: "Field", name: { kind: "Name", value: "description" } },
                            { kind: "Field", name: { kind: "Name", value: "totalAmount" } },
                            { kind: "Field", name: { kind: "Name", value: "totalAmountEur" } },
                            { kind: "Field", name: { kind: "Name", value: "dateInvoice" } },
                            { kind: "Field", name: { kind: "Name", value: "dateDue" } },
                            { kind: "Field", name: { kind: "Name", value: "dateEntry" } },
                            { kind: "Field", name: { kind: "Name", value: "dateOrder" } },
                            { kind: "Field", name: { kind: "Name", value: "orderNumber" } },
                            { kind: "Field", name: { kind: "Name", value: "allocationStatus" } },
                            { kind: "Field", name: { kind: "Name", value: "allocatedToParentExpectedRevenue" } },
                            { kind: "Field", name: { kind: "Name", value: "allocatedToAllExpectedRevenue" } },
                            { kind: "Field", name: { kind: "Name", value: "idExact" } },
                            { kind: "Field", name: { kind: "Name", value: "vatAmountDc" } },
                            { kind: "Field", name: { kind: "Name", value: "vatAmountFc" } },
                            { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                            { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                            { kind: "Field", name: { kind: "Name", value: "isDeleted" } },
                            { kind: "Field", name: { kind: "Name", value: "invoiceStatusSummary" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "expectedRevenue" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "totalAmount" } },
                                  { kind: "Field", name: { kind: "Name", value: "dateExpected" } },
                                  { kind: "Field", name: { kind: "Name", value: "allocatedFromParentInvoice" } },
                                  { kind: "Field", name: { kind: "Name", value: "paidEur" } },
                                  { kind: "Field", name: { kind: "Name", value: "totalAmountPlusChanges" } },
                                  { kind: "Field", name: { kind: "Name", value: "invoiceSumEurAllocatedToExpectedRevenue" } },
                                  { kind: "Field", name: { kind: "Name", value: "invoiceStatusSummary" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "assignedAt" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "expectedRevenue" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "totalAmount" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<CreateorupdateinvoicesonexpectedrevenueMutation, CreateorupdateinvoicesonexpectedrevenueMutationVariables>;
export const ProductDocument = ({
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Product" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ProductCreateInput" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "productcreate" },
            arguments: [{ kind: "Argument", name: { kind: "Name", value: "input" }, value: { kind: "Variable", name: { kind: "Name", value: "input" } } }],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Product" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "isActive" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "category" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Error" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "errorCode" } },
                      { kind: "Field", name: { kind: "Name", value: "message" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<ProductMutation, ProductMutationVariables>;
export const MarkCheckListItemActionAsDoneDocument = ({
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "MarkCheckListItemActionAsDone" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "MarkCheckListItemActionAsDoneInput" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "markchecklistitemactionasdone" },
            arguments: [{ kind: "Argument", name: { kind: "Name", value: "input" }, value: { kind: "Variable", name: { kind: "Name", value: "input" } } }],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CheckListItemAction" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "status" } },
                      { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedBy" },
                        selectionSet: { kind: "SelectionSet", selections: [{ kind: "Field", name: { kind: "Name", value: "fullName" } }] },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Error" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "errorCode" } },
                      { kind: "Field", name: { kind: "Name", value: "message" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<MarkCheckListItemActionAsDoneMutation, MarkCheckListItemActionAsDoneMutationVariables>;
export const MarkDealConditionAsConfirmedDocument = ({
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "MarkDealConditionAsConfirmed" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "MarkDealConditionAsConfirmedInput" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "markdealconditionasconfirmed" },
            arguments: [{ kind: "Argument", name: { kind: "Name", value: "input" }, value: { kind: "Variable", name: { kind: "Name", value: "input" } } }],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "DealCondition" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "isConfirmed" } },
                      { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedBy" },
                        selectionSet: { kind: "SelectionSet", selections: [{ kind: "Field", name: { kind: "Name", value: "fullName" } }] },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Error" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "errorCode" } },
                      { kind: "Field", name: { kind: "Name", value: "message" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<MarkDealConditionAsConfirmedMutation, MarkDealConditionAsConfirmedMutationVariables>;
export const MarkDealConfirmationAsConfirmedDocument = ({
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "MarkDealConfirmationAsConfirmed" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "MarkDealConfirmationAsConfirmedInput" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "markdealconfirmationasconfirmed" },
            arguments: [{ kind: "Argument", name: { kind: "Name", value: "input" }, value: { kind: "Variable", name: { kind: "Name", value: "input" } } }],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "DealConfirmation" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "isConfirmed" } },
                      { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedBy" },
                        selectionSet: { kind: "SelectionSet", selections: [{ kind: "Field", name: { kind: "Name", value: "fullName" } }] },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Error" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "errorCode" } },
                      { kind: "Field", name: { kind: "Name", value: "message" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<MarkDealConfirmationAsConfirmedMutation, MarkDealConfirmationAsConfirmedMutationVariables>;
export const ClientUpdateDocument = ({
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ClientUpdate" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ClientUpdateInput" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "clientupdate" },
            arguments: [{ kind: "Argument", name: { kind: "Name", value: "input" }, value: { kind: "Variable", name: { kind: "Name", value: "input" } } }],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Client" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "__typename" } },
                      { kind: "Field", name: { kind: "Name", value: "companyName" } },
                      { kind: "Field", name: { kind: "Name", value: "firstDealDate" } },
                      { kind: "Field", name: { kind: "Name", value: "idExact" } },
                      { kind: "Field", name: { kind: "Name", value: "companyNameExact" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "broker" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "brokerName" } },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: { kind: "SelectionSet", selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }] },
                      },
                      { kind: "Field", name: { kind: "Name", value: "isActive" } },
                      { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Error" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "errorCode" } },
                      { kind: "Field", name: { kind: "Name", value: "message" } },
                      { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<ClientUpdateMutation, ClientUpdateMutationVariables>;
export const ExpectedRevenueDocument = ({
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ExpectedRevenue" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ExpectedRevenueInput" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createexpectedrevenue" },
            arguments: [{ kind: "Argument", name: { kind: "Name", value: "input" }, value: { kind: "Variable", name: { kind: "Name", value: "input" } } }],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "ExpectedRevenue" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "deal" },
                        selectionSet: { kind: "SelectionSet", selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }] },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "client" },
                        selectionSet: { kind: "SelectionSet", selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }] },
                      },
                      { kind: "Field", name: { kind: "Name", value: "totalAmount" } },
                      { kind: "Field", name: { kind: "Name", value: "volume" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                        selectionSet: { kind: "SelectionSet", selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }] },
                      },
                      { kind: "Field", name: { kind: "Name", value: "dateExpected" } },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "ExpectedRevenueError" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "errorCode" } },
                      { kind: "Field", name: { kind: "Name", value: "message" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<ExpectedRevenueMutation, ExpectedRevenueMutationVariables>;
export const ProductUpdateDocument = ({
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ProductUpdate" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ProductUpdateInput" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "productupdate" },
            arguments: [{ kind: "Argument", name: { kind: "Name", value: "input" }, value: { kind: "Variable", name: { kind: "Name", value: "input" } } }],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Product" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "isActive" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "category" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Error" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "errorCode" } },
                      { kind: "Field", name: { kind: "Name", value: "message" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<ProductUpdateMutation, ProductUpdateMutationVariables>;
export const ChecklistitemscountDocument = ({
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Checklistitemscount" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "QueryCheckListItemInput" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "checklistitemscount" },
            arguments: [{ kind: "Argument", name: { kind: "Name", value: "input" }, value: { kind: "Variable", name: { kind: "Name", value: "input" } } }],
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<ChecklistitemscountQuery, ChecklistitemscountQueryVariables>;
export const ChecklistitemsDocument = ({
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Checklistitems" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "QueryCheckListItemInput" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "checklistitems" },
            arguments: [{ kind: "Argument", name: { kind: "Name", value: "input" }, value: { kind: "Variable", name: { kind: "Name", value: "input" } } }],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                { kind: "Field", name: { kind: "Name", value: "checkListItemType" } },
                { kind: "Field", name: { kind: "Name", value: "isCompleted" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "client" },
                  selectionSet: { kind: "SelectionSet", selections: [{ kind: "Field", name: { kind: "Name", value: "companyName" } }] },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "deal" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "olyxRef" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "currentCheckListItemAction" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "assignedTo" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "fullName" } },
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "actionType" } },
                      { kind: "Field", name: { kind: "Name", value: "status" } },
                      { kind: "Field", name: { kind: "Name", value: "toBeResolvedAt" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "updatedBy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "imageUrl" } },
                      { kind: "Field", name: { kind: "Name", value: "fullName" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<ChecklistitemsQuery, ChecklistitemsQueryVariables>;
export const ClientFormOptionsDocument = ({
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "clientFormOptions" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "clients" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "companyName" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "clientgroups" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "clientGroupName" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "brokers" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "brokerName" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "countries" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<ClientFormOptionsQuery, ClientFormOptionsQueryVariables>;
export const DealconditionsDocument = ({
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "dealconditions" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "QueryDealConditionInput" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "dealconditions" },
            arguments: [{ kind: "Argument", name: { kind: "Name", value: "input" }, value: { kind: "Variable", name: { kind: "Name", value: "input" } } }],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "DealCondition" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdBy" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "imageUrl" } },
                            { kind: "Field", name: { kind: "Name", value: "fullName" } },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "deal" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "olyxRef" } },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "description" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "isConfirmed" } },
                      { kind: "Field", name: { kind: "Name", value: "isDeleted" } },
                      { kind: "Field", name: { kind: "Name", value: "toBeResolvedAt" } },
                      { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedBy" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "imageUrl" } },
                            { kind: "Field", name: { kind: "Name", value: "fullName" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Error" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "errorCode" } },
                      { kind: "Field", name: { kind: "Name", value: "message" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<DealconditionsQuery, DealconditionsQueryVariables>;
export const DealconditionscountDocument = ({
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "dealconditionscount" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "QueryDealConditionInput" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "dealconditionscount" },
            arguments: [{ kind: "Argument", name: { kind: "Name", value: "input" }, value: { kind: "Variable", name: { kind: "Name", value: "input" } } }],
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<DealconditionscountQuery, DealconditionscountQueryVariables>;
export const DealconfirmationsDocument = ({
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "dealconfirmations" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "QueryDealConfirmationInput" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "dealconfirmations" },
            arguments: [{ kind: "Argument", name: { kind: "Name", value: "input" }, value: { kind: "Variable", name: { kind: "Name", value: "input" } } }],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "DealConfirmation" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                      { kind: "Field", name: { kind: "Name", value: "isConfirmed" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "deal" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "olyxRef" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "seller" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "companyName" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "buyer" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "companyName" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "tradeSide" } },
                      { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedBy" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "imageUrl" } },
                            { kind: "Field", name: { kind: "Name", value: "fullName" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Error" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "errorCode" } },
                      { kind: "Field", name: { kind: "Name", value: "message" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<DealconfirmationsQuery, DealconfirmationsQueryVariables>;
export const DealconfirmationscountDocument = ({
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "dealconfirmationscount" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "QueryDealConfirmationInput" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "dealconfirmationscount" },
            arguments: [{ kind: "Argument", name: { kind: "Name", value: "input" }, value: { kind: "Variable", name: { kind: "Name", value: "input" } } }],
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<DealconfirmationscountQuery, DealconfirmationscountQueryVariables>;
export const DealnotesoverviewDocument = ({
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "dealnotesoverview" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "QueryDealNotesOverviewInput" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "dealnotesoverview" },
            arguments: [{ kind: "Argument", name: { kind: "Name", value: "input" }, value: { kind: "Variable", name: { kind: "Name", value: "input" } } }],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "dealNotesTodo" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdBy" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "fullName" } },
                            { kind: "Field", name: { kind: "Name", value: "imageUrl" } },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "note" } },
                      { kind: "Field", name: { kind: "Name", value: "inProgressAt" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "inProgressBy" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "fullName" } },
                            { kind: "Field", name: { kind: "Name", value: "imageUrl" } },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "doneAt" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "doneBy" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "fullName" } },
                            { kind: "Field", name: { kind: "Name", value: "imageUrl" } },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "status" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "deal" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "olyxRef" } },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "mentions" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "userInfo" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "userUid" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "dealNotesInProgress" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdBy" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "fullName" } },
                            { kind: "Field", name: { kind: "Name", value: "imageUrl" } },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "note" } },
                      { kind: "Field", name: { kind: "Name", value: "inProgressAt" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "inProgressBy" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "fullName" } },
                            { kind: "Field", name: { kind: "Name", value: "imageUrl" } },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "doneAt" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "doneBy" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "fullName" } },
                            { kind: "Field", name: { kind: "Name", value: "imageUrl" } },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "status" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "deal" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "olyxRef" } },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "mentions" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "userInfo" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "userUid" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "dealNotesDone" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdBy" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "fullName" } },
                            { kind: "Field", name: { kind: "Name", value: "imageUrl" } },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "note" } },
                      { kind: "Field", name: { kind: "Name", value: "inProgressAt" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "inProgressBy" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "fullName" } },
                            { kind: "Field", name: { kind: "Name", value: "imageUrl" } },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "doneAt" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "doneBy" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "fullName" } },
                            { kind: "Field", name: { kind: "Name", value: "imageUrl" } },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "status" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "deal" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "olyxRef" } },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "mentions" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "userInfo" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "userUid" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<DealnotesoverviewQuery, DealnotesoverviewQueryVariables>;
export const DealstatisticsDocument = ({
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "dealstatistics" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "QueryDealsInput" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "dealstatistics" },
            arguments: [{ kind: "Argument", name: { kind: "Name", value: "input" }, value: { kind: "Variable", name: { kind: "Name", value: "input" } } }],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "after" } },
                { kind: "Field", name: { kind: "Name", value: "before" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brokers" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "brokerName" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "userInfo" },
                        selectionSet: { kind: "SelectionSet", selections: [{ kind: "Field", name: { kind: "Name", value: "imageUrl" } }] },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "clientStatistics" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "client" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "companyName" } },
                            { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "broker" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "brokerName" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "commissionInEur" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "commissionTotalEur" } },
                { kind: "Field", name: { kind: "Name", value: "dealCount" } },
                { kind: "Field", name: { kind: "Name", value: "distinctBrokerCount" } },
                { kind: "Field", name: { kind: "Name", value: "distinctClientCount" } },
                { kind: "Field", name: { kind: "Name", value: "distinctProductCount" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "productStatistics" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "product" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "category" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "name" } },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "commissionInEur" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "totalVolumePerUnit" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "product" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "volumePerUnit" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "unit" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "descriptionShort" } },
                                  { kind: "Field", name: { kind: "Name", value: "description" } },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "totalVolume" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<DealstatisticsQuery, DealstatisticsQueryVariables>;
export const InvoicesDocument = ({
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Invoices" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "QueryInvoiceInput" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "invoices" },
            arguments: [{ kind: "Argument", name: { kind: "Name", value: "input" }, value: { kind: "Variable", name: { kind: "Name", value: "input" } } }],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "invoiceNumber" } },
                { kind: "Field", name: { kind: "Name", value: "isDeleted" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "type" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "client" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "companyName" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "totalAmount" } },
                { kind: "Field", name: { kind: "Name", value: "totalAmountEur" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "currency" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "description" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "dateInvoice" } },
                { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "invoiceStatus" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "orderNumber" } },
                { kind: "Field", name: { kind: "Name", value: "allocationStatus" } },
                { kind: "Field", name: { kind: "Name", value: "allocatedToAllExpectedRevenue" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "expectedRevenue" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "totalAmount" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "description" } },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "dateExpected" } },
                      { kind: "Field", name: { kind: "Name", value: "allocatedFromParentInvoice" } },
                      { kind: "Field", name: { kind: "Name", value: "allocatedFromAllInvoices" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "deal" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "olyxRef" } },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "expectedRevenueChanges" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "amount" } },
                            { kind: "Field", name: { kind: "Name", value: "reason" } },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "invoices" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "totalAmount" } },
                            { kind: "Field", name: { kind: "Name", value: "orderNumber" } },
                            { kind: "Field", name: { kind: "Name", value: "allocatedToParentExpectedRevenue" } },
                            { kind: "Field", name: { kind: "Name", value: "invoiceStatusSummary" } },
                            { kind: "Field", name: { kind: "Name", value: "invoiceNumber" } },
                            { kind: "Field", name: { kind: "Name", value: "dateOrder" } },
                            { kind: "Field", name: { kind: "Name", value: "isDeleted" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "description" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<InvoicesQuery, InvoicesQueryVariables>;
export const NetworkgraphdataDocument = ({
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "networkgraphdata" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "QueryDealsInput" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "networkgraphdata" },
            arguments: [{ kind: "Argument", name: { kind: "Name", value: "input" }, value: { kind: "Variable", name: { kind: "Name", value: "input" } } }],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "img" } },
                      { kind: "Field", name: { kind: "Name", value: "olyxRefs" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "links" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "source" } },
                      { kind: "Field", name: { kind: "Name", value: "target" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<NetworkgraphdataQuery, NetworkgraphdataQueryVariables>;
export const OpenDealNoteCountDocument = ({
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "OpenDealNoteCount" },
      selectionSet: { kind: "SelectionSet", selections: [{ kind: "Field", name: { kind: "Name", value: "opendealnotecount" } }] },
    },
  ],
} as unknown) as DocumentNode<OpenDealNoteCountQuery, OpenDealNoteCountQueryVariables>;
export const ProductFormOptionsDocument = ({
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "productFormOptions" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<ProductFormOptionsQuery, ProductFormOptionsQueryVariables>;
