import useBrokersExtensive from "../../hooks/useBrokersExtensive";
import helpers from "../../services/helpers";
import BrokerGroupContainer from "./elements/BrokerGroupContainer";

export default function WorkloadReportingPage() {
  const [allBrokers, refetchBrokers, networkStatusBrokers] = useBrokersExtensive();
  const allBrokersGrouped = allBrokers ? helpers.groupBrokersByHardcodedDesk(allBrokers.brokers) : [];

  return (
    <div className="p-4 xl:m-8 w-full flex justify-center flex-wrap">
      <div className="flex justify-start w-full xl:w-2/3 items-center">
        <p className="ml-[25%] xl:ml-[20%] w-1/5 xl:1/4 text-gray-dark text-[12px] ">Open checklist items</p>
        <p className="w-1/5 xl:1/4 text-gray-dark text-[12px] ">Open comments</p>
        <p className="w-1/5 xl:1/4 text-gray-dark text-[12px] ">Comments in progress</p>
      </div>
      {allBrokersGrouped.map((group) => (
        <BrokerGroupContainer group={group} />
      ))}
    </div>
  );
}
