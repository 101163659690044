import useDealConfirmationsCount from "../../../typedHooks/queries/queryDealConfirmationsCount";
import useDealConditionsCount from "../../../typedHooks/queries/queryDealConditionsCount";
import useCheckListItemsCount from "../../../typedHooks/queries/queryCheckListITemsCount";
import useDealNotesOverview from "../../../typedHooks/queries/queryDealNotesOverview";
import { useMemo } from "react";
import { addDays } from "date-fns";
import { CheckListItemActionStatus, CheckListItemType } from "../../../codegen/gql/graphql";

export default function BrokerContainer({ broker }) {
  const oneWeekFromNow = useMemo(() => addDays(new Date(), 7), []);
  const userIdAsInt = parseInt(broker.userInfo.id);

  const { dataFromQuery: toDoCheckListItemsCount, refetch: refetchToDoCheckListItemsCount } = useCheckListItemsCount({
    checkListItemTypes: [CheckListItemType.Clientonboarding],
    status: CheckListItemActionStatus.Todo,
    userIds: [userIdAsInt],
  });

  const { dataFromQuery: openDealConfirmationsCount, refetch: refetchOpen } = useDealConfirmationsCount({
    userIds: [userIdAsInt],
    isConfirmed: false,
  });

  const { dataFromQuery: openDealConditionsCount, refetch: refetchOpenConditions } = useDealConditionsCount({
    isConfirmed: false,
    userIds: [userIdAsInt],
    toBeResolvedBefore: oneWeekFromNow,
  });

  const queryDealnotesOverviewInput = {
    userIds: [userIdAsInt],
    isMentioned: true,
    hasSent: false,
    includeNoMention: false,
  };

  const { dataFromQuery: dealNotesOverviewData } = useDealNotesOverview(queryDealnotesOverviewInput);

  const counts = useMemo(() => {
    const openComments = dealNotesOverviewData?.dealnotesoverview.dealNotesTodo.length ?? 0;
    const inProgressComments = dealNotesOverviewData?.dealnotesoverview.dealNotesInProgress.length ?? 0;
    const checklistItems =
      (toDoCheckListItemsCount?.checklistitemscount ?? 0) +
      (openDealConfirmationsCount?.dealconfirmationscount ?? 0) +
      (openDealConditionsCount?.dealconditionscount ?? 0);

    return {
      openComments,
      inProgressComments,
      checklistItems,
    };
  }, [dealNotesOverviewData, toDoCheckListItemsCount, openDealConfirmationsCount, openDealConditionsCount]);

  return (
    <div className="w-full h-[32px] flex items-center justify-start">
      <div className="w-1/4 xl:w-1/5">
        <p className="text-[14px] xl:text-[20px]">{broker.brokerName}</p>
      </div>
      <div className="w-1/4 xl:w-1/5 ">
        <p className="text-[12px] xl:text-[16px]">{counts.checklistItems}</p>
      </div>
      <div className="w-1/4 xl:w-1/5 ">
        <p className="text-[12px] xl:text-[16px]">{counts.openComments}</p>
      </div>
      <div className="w-1/4 xl:w-1/5">
        <p className="text-[12px] xl:text-[16px] text-slate-500">{counts.inProgressComments}</p>
      </div>
    </div>
  );
}
