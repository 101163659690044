export const DESKS = {
  BIOGAS: "Biogas",
  APAC: "APAC",
  LATAM: "LATAM",
  VOLUNTARY_CARBON: "Voluntary Carbon",
  DACH: "DACH",
  ATLANTIC: "Atlantic",
  OTHER: "Other",
};

export const brokersGroupedByDesk = [
  { desk: DESKS.BIOGAS, brokers: [] },
  { desk: DESKS.APAC, brokers: [] },
  { desk: DESKS.LATAM, brokers: [] },
  { desk: DESKS.VOLUNTARY_CARBON, brokers: [] },
  { desk: DESKS.DACH, brokers: [] },
  { desk: DESKS.ATLANTIC, brokers: [] },
  { desk: DESKS.OTHER, brokers: [] },
];

export const brokerDeskMapping = {
  Oscar: DESKS.ATLANTIC,
  Leo: DESKS.ATLANTIC,
  Guido: DESKS.ATLANTIC,
  Mike: DESKS.ATLANTIC,
  Jakub: DESKS.ATLANTIC,
  Davide: DESKS.LATAM,
  Francesca: DESKS.LATAM,
  Yan: DESKS.APAC,
  Daniels: DESKS.APAC,
  Cassie: DESKS.APAC,
  Kuan: DESKS.APAC,
  Nacho: DESKS.APAC,
  Michel: DESKS.DACH,
  Paula: DESKS.DACH,
  Niek: DESKS.DACH,
  Philip: DESKS.DACH,
  Cornelius: DESKS.DACH,
  Khaled: DESKS.DACH,
  Tim: DESKS.DACH,
  Katharina: DESKS.DACH,
  Felix: DESKS.DACH,
  Riaan: DESKS.BIOGAS,
  Thomas: DESKS.BIOGAS,
  Jacob: DESKS.BIOGAS,
  Jonas: DESKS.BIOGAS,
  Yusheng: DESKS.VOLUNTARY_CARBON,
  Fabio: DESKS.VOLUNTARY_CARBON,
};
