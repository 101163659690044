import { gql } from "@apollo/client";

const GET_BROKERSEXTENSIVE = gql`
  query getBrokers {
    brokers {
      id
      brokerName
      isActive
      userInfo {
        id
      }
    }
  }
`;

export default GET_BROKERSEXTENSIVE;
