import CheckIcon from "@mui/icons-material/Check";
import { format } from "date-fns";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import AvatarTooltip from "../AvatarTooltip/AvatarTooltip";
import ChecklistItemForm from "./elements/ChecklistItemForm";
import helpers from "../../services/helpers";

// checklistItem needs to be any because it can be either a DealCondition or a DealConfirmation
// as soon as the new checkListItem structure is implemented, this should be updated
// TODO: remove isCompleted from the props and use the soon to be added isCompleted for all the checklistItem
function ChecklistItem({
  checklistItem,
  olyxRef,
  mutation,
  isCompleted,
}: {
  checklistItem: any;
  olyxRef?: string;
  mutation: () => void;
  isCompleted: boolean;
}) {
  const [formIsOpen, setFormIsOpen] = useState(false);
  const history = useHistory();
  const today = new Date();
  const isDealCondition = checklistItem.__typename === "DealCondition";
  const isCheckListItem = checklistItem.__typename === "CheckListItem";

  // TODO: as soon as the new checkListItem structure is implemented itemToBeResolvedAtDate is not needed
  const itemToBeResolvedAtDate =
    isCheckListItem && checklistItem.currentCheckListItemAction?.toBeResolvedAt
      ? new Date(checklistItem.currentCheckListItemAction.toBeResolvedAt)
      : checklistItem.toBeResolvedAt
      ? new Date(checklistItem.toBeResolvedAt)
      : null;

  // TODO: as long as Condition and Confirmation are not separated, this is the way to get the correct text
  const { thisItemsConfirmationAcceptMessage, titleText, isConfirmedText } = isCheckListItem
    ? helpers.getChecklistItemsTextContents(checklistItem)
    : helpers.getChecklistItemsTextBasedOnItem(checklistItem);

  return (
    <div className="w-full">
      {isCompleted && (
        <div className="w-full flex flex-wrap h-[35px]">
          <div className="w-full flex flex-wrap gap-2 items-center">
            <div className="w-[27px] h-[27px] block rounded-full">
              <AvatarTooltip
                broker={checklistItem.updatedBy.fullName ? checklistItem.updatedBy.fullName : ""}
                img={checklistItem.updatedBy.imageUrl ? checklistItem.updatedBy.imageUrl : "default.png"}
                hover={true}
              />
            </div>
            <p className="text-xs no-leading">{checklistItem.updatedAt ? format(new Date(checklistItem.updatedAt), "d MMM yyyy") : ""}</p>
          </div>
          <div className="w-[2px] h-[8px] ml-[13px] bg-[#f4eced]"></div>
        </div>
      )}
      <div
        className={`w-full h-fit min-h-[36px] rounded-xl border-[1px] border-[#f4eced] flex flex-wrap items-center gap-x-4 ${
          checklistItem && isCompleted ? "" : " hover:bg-[#d8edf4]"
        }`}
      >
        <div
          onClick={() => checklistItem && !isCompleted && setFormIsOpen(!formIsOpen)}
          className={`relative w-full flex flex-wrap p-[10px] ${isCompleted ? "cursor-default" : "cursor-pointer"}`}
        >
          <div className={`flex gap-2 w-full text-[12px] ${olyxRef ? "w-[calc(100%-40px)]" : "w-full"}`}>
            <button
              className={`self-baseline relative p-[3px] ${
                isCompleted ? "bg-[#22B0FF] p-[6px] mt-[2px] cursor-default" : "bg-[#DC2334] m-[3px] mt-[5px]"
              } rounded-full`}
            >
              {isCompleted && <CheckIcon className="absolute top-[1px] left-[1px]" style={{ fontSize: 10, color: "white" }} />}
            </button>
            {isDealCondition && <p className="font-smallText max-w-[calc(100%-20px)] self-center leading-none">Condition:</p>}
            {/* TODO: as soon as the new checkListItem structure is implemented, this should be updated */}
            {isCheckListItem && <p className="font-smallText max-w-[calc(100%-20px)] self-center leading-none">Onboarding:</p>}
            <p className={`${isDealCondition || isCheckListItem ? "text-[#A7A7A7]" : ""} font-smallText max-w-[calc(100%-20px)] self-center leading-none`}>
              {isCompleted ? isConfirmedText : titleText}
            </p>
          </div>
          {olyxRef && (
            <div className="text-primary-blue right-0 ml-auto">
              {" "}
              <Link
                className="cursor-pointer link-deal"
                to={{
                  pathname: `/mydeals/${checklistItem.deal?.id}`,
                  state: { history: history.location },
                }}
              >
                {olyxRef}
              </Link>
            </div>
          )}
          {itemToBeResolvedAtDate && (
            <div
              className={` min-h-[16px] flex w-fit border-[1px] border-[#f4eced] rounded-md px-1 align-center mt-[4px] ml-[16px] ${
                new Date(itemToBeResolvedAtDate) < today && !isCompleted ? "bg-red-500 border-red-500 text-white" : "bg-[#f4eced] text-black"
              } ${olyxRef ? "relative" : "relative"}`}
            >
              <p className="text-[10px] w-[30px] text-right pr-2 flex items-center justify-end leading-none">due:</p>
              <p className="text-[10px] w-[70px] text-right flex items-center justify-center leading-none">
                {itemToBeResolvedAtDate ? format(new Date(itemToBeResolvedAtDate), "d MMM yyyy") : ""}
              </p>
            </div>
          )}
        </div>
        {formIsOpen && !isCompleted && (
          <ChecklistItemForm itemId={checklistItem.id} itemAcceptMessage={thisItemsConfirmationAcceptMessage} markItemAsConfirmed={mutation} />
        )}
      </div>
    </div>
  );
}

export default ChecklistItem;
