import { Component } from "react";
import PropTypes from "prop-types";
import { BrowserRouter, Switch, Redirect, Route } from "react-router-dom";
import HomeContent from "../components/HomeContent";
import AdminContent from "../components/AdminContent";
import UserContent from "../components/UserContent";
import NotFoundContent from "../components/NotFoundContent";
import Dashboard from "../pages/Dashboard";
import DealPage from "../pages/DealPage";
import AddDealPage from "../pages/AddDealPage";
import AddDealExtensivePage from "../pages/AddDealExtensivePage";
import ClientPage from "../pages/ClientPage";
import SandboxPage from "../pages/SandboxPage/SandboxPage";
import SpreadVisualiserPage from "../pages/SpreadVisualiserPage";
import StatisticsPage from "../pages/StatisticsPage/StatisticsPage";
import TimelinePage from "../pages/TimelinePage";
import FeedbackPage from "../pages/FeedbackPage";
import HomePage from "../pages/HomePage";
import MaintenancePage from "../pages/MaintenancePage";
import MyDealsPage from "../pages/MyDealsPage";
import ProductHistoryPage from "../pages/ProductHistoryPage/ProductHistoryPage";
import MyDealsDetailPage from "../pages/MyDealsDetailPage/MyDealsDetailPage";
import LeaderboardPage from "../pages/LeaderboardPage/LeaderboardPage";
import ExternalLinksPage from "../pages/ExternalLinksPage/ExternalLinksPage";
import DealManagementPage from "../pages/DealManagementPage/";
import InvoicePage from "../pages/InvoicePage/";
import NetworkGraphPage from "../pages/NetworkGraphPage/NetworkGraphPage";
import ReportingPage from "../pages/ReportingPage/ReportingPage";
import WorkloadReportingPage from "../pages/WorkloadReportingPage/WorkloadReportingPage";

class Router extends Component {
  render() {
    // Properties
    const { user, roles, bar } = this.props;

    // Functions
    const { openSnackbar } = this.props;

    return (
      <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
        {bar}

        <Switch>
          {process.env.REACT_APP_IS_MAINTENANCE === "true" && <MaintenancePage />}

          <Route path="/" exact>
            {user ? <HomePage /> : <HomeContent user={user} openSnackbar={openSnackbar} />}
          </Route>

          <Route path="/networkgraph">{user ? <NetworkGraphPage /> : <Redirect to="/" />}</Route>

          <Route path="/admin">{user && roles.includes("admin") ? <AdminContent /> : <Redirect to="/" />}</Route>

          <Route path="/user/:userId">{user ? <UserContent /> : <Redirect to="/" />}</Route>

          <Route path="/dashboard">{user ? <Dashboard config={this.props.config} /> : <Redirect to="/" />}</Route>

          <Route path="/producthistory/:productId">{user ? <ProductHistoryPage /> : <Redirect to="/" />}</Route>

          <Route path="/timeline">{user ? <TimelinePage /> : <Redirect to="/" />}</Route>

          <Route path="/deals">{user ? <DealPage /> : <Redirect to="/" />}</Route>

          <Route path="/adddeal">{user ? <AddDealPage /> : <Redirect to="/" />}</Route>

          <Route path="/adddealextensive">{user ? <AddDealExtensivePage /> : <Redirect to="/" />}</Route>

          <Route path="/clients">{user ? <ClientPage /> : <Redirect to="/" />}</Route>

          <Route path="/feedback">{user ? <FeedbackPage /> : <Redirect to="/" />}</Route>

          <Route path="/sandbox">{user ? <SandboxPage /> : <Redirect to="/" />}</Route>

          <Route path="/spreadvisualiser">{user ? <SpreadVisualiserPage /> : <Redirect to="/" />}</Route>

          <Route path="/externallinks">{user ? <ExternalLinksPage /> : <Redirect to="/" />}</Route>

          <Route path="/reporting">{user ? <ReportingPage /> : <Redirect to="/" />}</Route>

          <Route path="/workload">{user ? <WorkloadReportingPage /> : <Redirect to="/" />}</Route>

          <Route path="/statistics">{user ? <StatisticsPage /> : <Redirect to="/" />}</Route>

          <Route exact path="/mydeals/:dealId">
            {user ? <MyDealsDetailPage /> : <Redirect to="/" />}
          </Route>

          <Route path="/mydeals">{user ? <MyDealsPage /> : <Redirect to="/" />}</Route>
          <Route path="/leaderboard">{user ? <LeaderboardPage /> : <Redirect to="/" />}</Route>
          <Route path="/dealmanagement">{user ? <DealManagementPage /> : <Redirect to="/" />}</Route>
          <Route path="/invoices">{user ? <InvoicePage /> : <Redirect to="/" />}</Route>

          <Route>
            <NotFoundContent />
          </Route>
        </Switch>
      </BrowserRouter>
    );
  }
}

Router.propTypes = {
  // Properties
  user: PropTypes.object,
  roles: PropTypes.array.isRequired,
  bar: PropTypes.element,

  // Functions
  openSnackbar: PropTypes.func.isRequired,
};

export default Router;
