import { CheckListItemsData } from "../../interfaces/CheckListItems";
import { graphql } from "../../codegen/gql";
import useGeneratedTypeQueryWithPagination from "../helpers/useGeneratedTypeQueryWithPagination";
import { QueryCheckListItemInput } from "../../codegen/gql/graphql";
const GET_CHECKLISTITEMS = graphql(`
  query Checklistitems($input: QueryCheckListItemInput!) {
    checklistitems(input: $input) {
      id
      createdAt
      updatedAt
      checkListItemType
      isCompleted
      client {
        companyName
      }
      deal {
        id
        olyxRef
      }
      currentCheckListItemAction {
        assignedTo {
          fullName
          id
        }
        id
        actionType
        status
        toBeResolvedAt
      }
      updatedBy {
        id
        imageUrl
        fullName
      }
    }
  }
`);

const useCheckListItems = (input: QueryCheckListItemInput) =>
  useGeneratedTypeQueryWithPagination<CheckListItemsData, QueryCheckListItemInput>(GET_CHECKLISTITEMS, input);

export default useCheckListItems;
